import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { User } from "./classes/User";
import { AuthService } from "./services/auth.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Location } from '@angular/common';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  user: User;
  authToken: string | null;
  loading = true;
  userHover: boolean;

  params: any = {};

  err = "";

  origPath;

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private loc: Location
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      // console.log("Params => ", params);
      let keys = Object.keys(params);
      this.origPath = this.loc.path().split("?")[0];
      if (keys.length) {
        for (let k of keys) {
          if (k === "ver" || this.origPath != "/support") continue;
          else this.params[k] = params[k];
        }
      }

      this.versionCheck();
      let token = this.auth.getToken();
      if (token) this.getUserInfo();
      else this.removeUserInfo();
    });
  }

  getUserInfo() {
    let userObs = this.auth.getHostDetail().subscribe(
      (res: any) => {
        this.user = new User(res);
        this.loading = false;
        localStorage.setItem("userid", this.user.userid);
        localStorage.setItem("user_detail", JSON.stringify(this.user));
      },
      (err: HttpErrorResponse) => {
        this.loading = false;
        if (err.status === 401) { this.removeUserInfo(); }
        this.err = err.error.detail;
        if (this.err.includes("[Errno 111]"))
          this.err =
            "Can't connect to redis. Please contact system administrator.";
      }
    );
  }

  versionCheck() {
    console.log("Checking version...");
    this.auth.getCurrentVersion().subscribe(
      (res: string) => {
        let ver = localStorage.getItem("api_version");
        if (ver !== res) {
          localStorage.setItem("api_version", res);
          let p = "";
          let keys = Object.keys(this.params);
          for (let k of keys) {
            p = `${p}&${k}=${this.params[k]}`;
          }
          let loc = window.location;
          window.location.href = `${loc.origin}${loc.pathname}?ver=${res}${p}`;
        } else {
          let token = this.auth.getToken();
          this.authToken = token;
          const path = this.router.url.split("?")[0];
          this.router.navigate([path], {
            queryParams: this.params,
            queryParamsHandling: "",
          });
          if (token) this.getUserInfo();
          else this.loading = false;
        }
      },
      (err) => (this.err = err.error.detail)
    );
  }

  removeUserInfo() {
    this.user = null;
    localStorage.removeItem("user_detail");
    localStorage.removeItem("userid");
    this.auth.setToken("");
    this.authToken = "";
    const basePath = this.origPath.split("/")[1];
    if (basePath !== "forgot-password" && basePath !== "new-password") {
      this.router.navigate(["/login"]);
    }
  }
}
