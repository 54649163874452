import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Game } from '../classes/Game';

@Component({
	selector: 'app-game-recap-stats',
	templateUrl: './game-recap-stats.component.html',
	styleUrls: ['./game-recap-stats.component.css']
})
export class GameRecapStatsComponent implements OnInit {

	@Input() game: Game;
	@Input() shift: Shift;
	@Input() mode: '' | 'edit' | 'verify';

	@Output() doneEditing: EventEmitter<ShiftStats> = new EventEmitter();
	@Output() cancelEdit: EventEmitter<boolean> = new EventEmitter();
	@Output() verifyResult: EventEmitter<string[]> = new EventEmitter();

	stats: ShiftStats;

	verifyMsgs: string[] = [];

	verifiedEditOnly: boolean;

	constructor() { }

	ngOnInit(): void {
		this.resetStats();
		if (this.mode === 'verify') {
			this.verifiedEditOnly = true;
			this.verify();
		}
	}

	verify() {
		const uniqueHosts = [...new Set(this.game.hosts)]
		let allowGameValidation = true;

		if (uniqueHosts.length > 1) {
			if (this.game.status === "live" && this.game.hosts_shift_status.indexOf("live") > -1) {
				allowGameValidation = false;
			}
		}

		let msgs = [];
		if (this.stats.no_show) {
			if (!this.stats.internal_notes) {
				msgs.push("Please let us know why the show didn't happen.");
			}
		}
		else {
			if ((!this.stats.game_start || !this.stats.game_end) && allowGameValidation) {
				msgs.push("Missing or invalid game times")
			}
			let keyAlias = {
				qm_teams: "Team Count",
				total_players: "Player Count",
				empty_tables: "Empty Tables",
				question_rating: "Question Rating",
			}
			for (let k of Object.keys(keyAlias)) {
				if (this.stats[k] === null || this.stats[k] === undefined) {
					if (!this.game.use_external_app || k !== 'qm_teams') {
						msgs.push(`Missing or invalid ${keyAlias[k]}`)
					}
				}
			}
		}
		if (this.mode === 'verify') this.verifyMsgs = msgs;
		return msgs;
	}

	resetStats() {
		this.stats = {
			game_start: this.shift.game_start ?
				this.shift.game_start : this.game.first_action,
			game_end: this.shift.game_end ?
				this.shift.game_end : this.game.last_action,
			total_players: this.shift.total_players || this.shift.total_players === 0 ?
				this.shift.total_players : this.game.numPlayers(),
			qm_teams: this.game.use_external_app ?
				0 : (this.shift.qm_teams || this.shift.qm_teams === 0 ? this.shift.qm_teams : this.game.teams.length),
			empty_tables: this.shift.empty_tables,
			question_rating: this.shift.question_rating,
			internal_notes: this.shift.internal_notes,
			no_show: this.shift.no_show
		}
		if (!this.game.ended) this.stats.game_end = null;
	}

	setQuestionRating(rating: string) {
		this.stats.question_rating = parseInt(rating, 10);
	}

	cancel() {
		this.cancelEdit.emit(true);
		this.resetStats()
	}

	save() {
		if (this.verifiedEditOnly) {
			this.mode = 'verify';
			this.verifyMsgs = this.verify();
			if (this.verifyMsgs.length) return;
		}
		this.doneEditing.emit(this.stats)
	}
}
