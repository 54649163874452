import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../classes/User';
import { Router } from '@angular/router';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

	@Input() user: User;

	@Output() logout: EventEmitter<any> = new EventEmitter();

	menuOpen: boolean;
	userHover: boolean;
	activeModal = '';

	constructor(private router: Router) { }

	ngOnInit(): void {
		if (!this.user.venmo) {
			let dismissed = localStorage.getItem("dismissedVenmo");
			if (!dismissed) this.activeModal = "setVenmo";
		}

	}

	confirmLogout() {
		this.menuOpen = false;
		this.activeModal = 'logoutConfirm'
	}

	logoutUser() {
		this.logout.emit(true);
	}

	navTo(loc: any[]) {
		this.menuOpen = false;
		this.router.navigate(loc);
	}

	closeModalAndNavTo(loc: any[]) {
		this.activeModal = "";
		this.navTo(loc);
	}

	closeMenu() {
		this.menuOpen = false;
	}

	closeAndDismiss() {
		this.activeModal = "";
		localStorage.setItem("dismissedVenmo", "true");
	}

}
