<div class="routed-container">
	<div class="routed-content">
		<div *ngIf="loading" class="loading-message">LOADING...</div>
		<div *ngIf="!loading" class="profile-box">
			<h2>{{user.name}}</h2>
			<p class="email">{{user.email}}</p>
			<p *ngIf="!editVenmo" class="venmo-display">
				<span>
					<span>My Venmo: </span>
					<span *ngIf="!user.venmo">NOT SET</span>
				</span>
				<span>
					<span *ngIf="user.venmo">@{{user.venmo}}</span>
					<i (click)="editVenmo = true" class="material-icons">create</i>
				</span>
			</p>
			<div *ngIf="editVenmo" class="venmo-edit hrz-center">
				<div class="vert-center sm-px">
					<span>@<input #vTag [value]="user.venmo" type="text"></span>
				</div>
				<div class="vert-center sm-px">
					<button class="basic-btn-red" (click)="updateUser({venmo: vTag.value})">Submit</button>
				</div>
				<div class="vert-center sm-px">
					<button class="basic-btn-lgt-blue" (click)="editVenmo = false">Cancel</button>
				</div>
			</div>
		</div>
	</div>
</div>
