<h2 *ngIf="loading" class="loading-message">{{loading}}</h2>

<h2 *ngIf="err" class="error-message">{{err}}</h2>

<div *ngIf="!loading" class="routed-container">
	<div class="routed-content">
		<div class="routed-bumper">
			<div class="game-detail-header">
				<div class="vert-center">
					<h2 class="no-mb">{{game.venue}}</h2>
					<p class="no-m">{{game.date}}</p>
					<p>{{game.venue_phone}}<br>{{game.venue_address}}<br>{{game.venue_city}}{{game.venue_state ? ', ' :
						''}}{{game.venue_state}} {{game.venue_zip}}</p>
					<p *ngIf="game.question_set?.slide_link" class="no-m">
						<a href="{{game.question_set.slide_link}}" target="_blank">Slide Presentation</a>
						<span *ngIf="game.question_set.slide_password" class="md-ml">Password is
							'{{game.question_set.slide_password}}'</span>
					</p>
					<p class="no-mt">Status: <span
							[ngClass]="{live: game.status === 'live', scheduled: game.status === 'scheduled'}">{{game.status}}</span>
					</p>
				</div>
				<div class="vert-center md-pr header-action-button">
					<a *ngIf="game.status !== 'ended' && game.question_set?.download_link"
						class="sm-mb text-decoration-none" href={{game.question_set.download_link}} target="_blank">
						<button class="basic-btn-lgt-blue w-100-percent flex items-center justify-center">
							<i class="material-icons">download</i>
							<span>Download Set</span>
						</button>
					</a>
					<button 
						*ngIf="game.status !== 'ended' && game.status !== 'finalized' && (game?.question_set?.set_id || game?.title === 'bingo')"
						(click)="startGame()" class="basic-btn-red">Go Live</button>
				</div>
			</div>
			<div *ngIf="game?.title !== 'bingo'">
				<div *ngIf="game.status !== 'ended' && game?.question_set?.set_id">
					<h2 class="title-message">Questions</h2>
					<div *ngFor="let r of game.question_set.rounds" class="round-box">
						<h4 class="title-message">
							<span class="round-badge">Round {{r.round_order + 1}}</span>
							<span><br>{{r.title}}</span>
							<span *ngIf="r.subtitle"><br>{{r.subtitle}}</span>
						</h4>
						<p class="center-text lg-mb" *ngIf="r.player_instructions"><i>{{r.player_instructions}}</i></p>
						<div *ngIf="r.matches?.length" class="vert-center">
							<h5 class="center-text sm-mb">Possible answers:</h5>
							<div class="match-box">
								<div *ngFor="let m of r.matches">{{m}}</div>
							</div>
						</div>
						<div *ngFor="let q of r.questions">
							<p class="no-mb">
								<strong>{{q.question_order + 1}}. {{q.question_text}}</strong>
								<img *ngIf="q.image" src="{{q.image}}">
								<br>
								<em *ngIf="q.question_note">{{q.question_note}}</em>
							</p>
							<ul class="no-mt">
								<li *ngFor="let a of q.answers">
									<span *ngFor="let v of a.variations; let i = index">
										<span *ngIf="i > 0"> or </span>
										<span>{{v}}</span>
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<p *ngIf="!game?.question_set?.set_id">No Question set has been assigned to this game yet...</p>
			</div>
			<div *ngIf="game?.title === 'bingo'">
				<p>This is a Music Bingo game. Click "Go Live" after you arrive at the venue, then follow the link
					inside your game to be taken to the Music Bingo interface. <strong>After the game is finished, you
						will need to return to the host app to complete your recap.</strong></p>
			</div>
		</div>
	</div>
</div>