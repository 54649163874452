import { Pipe, PipeTransform } from '@angular/core';
import { Game } from './classes/Game';
import { Question } from './classes/QSet';
import { CorrectTeamAnswersByQuestionPipe } from './correct-team-answers-by-question.pipe';

@Pipe({
  name: 'correctAnswers'
})
export class CorrectAnswersPipe implements PipeTransform {

    transform(game: Game, round_id: number, team_code: string, resetter?: boolean) {
		
		let t_ans = game.answered['r_'+round_id];
		if (!t_ans) return null;
		t_ans = t_ans[`t_${team_code}`];
		if (!t_ans) return null;
		t_ans = t_ans.answers;
		if (!t_ans) return null;

		// convert string submissions into objects
		let t_sub = [];
		for (let i = 0; i < t_ans.length; i++) {
			let q_sub = [];
			for (let ri = 0; ri < t_ans[i].length; ri++) {
				q_sub.push({value: t_ans[i][ri], correct: false, score: 0});
			}
			t_sub.push(q_sub);
		}
		
		// get question data for each asked question
		let r_asked = [];
		let asks = game.asked['r_'+round_id];
		for (let a of asks) {
			let full_q = game.getQuestionById(a.question_id)
			r_asked.push(full_q);
		}

		// for each question:
		let check_pipe = new CorrectTeamAnswersByQuestionPipe();
		for (let qi = 0; qi < r_asked.length; qi++) {
			// check for correctness
			t_sub[qi] = check_pipe.transform(t_sub[qi], r_asked[qi]);
			// apply scores to each submission for this answer
			if (t_sub[qi]) for (let ri = 0; ri < t_sub[qi].length; ri++) {
				if (t_sub[qi][ri].correct) t_sub[qi][ri].score = r_asked[qi].score;
				else if (t_sub[qi][ri].value) t_sub[qi][ri].score = r_asked[qi].incorrect_score;
			}
		}
		return t_sub;
	}

}
