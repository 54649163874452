import { Pipe, PipeTransform } from '@angular/core';
import { Question } from './classes/QSet';

@Pipe({
  name: 'correctTeamAnswersByQuestion'
})
export class CorrectTeamAnswersByQuestionPipe implements PipeTransform {

	// Use this pipe to determine if a particular set of answers to a question are correct.
	// tranform takes one team's set of answers for a question and the question object itself


	transform(answers: string[] | ScoredAnswer[], question: Question) {
		// prep answers to be scored (convert to objects)
		let ans_objs: ScoredAnswer[] = [];
		if (!answers || !Array.isArray(answers)) return [];
		for (let a of answers) {
			if (typeof a === "string") ans_objs.push({value: a, correct: undefined});
			else ans_objs.push(a);
		}

		// for each answer, see if it matches a variation (and that no previous answers match it)
		let used_ans: number[] = [];
		for (let i = 0; i < ans_objs.length; i++) {
			let r = ans_objs[i];
			for (let x = 0; x < question.answers.length; x++) {
				let a = question.answers[x];
				let unique_variants = [...new Set(a.variations)];
				for (let v of unique_variants) {
					if (v === r.value) {
						r.original = a.original;
						if (!this.is_used(used_ans, x)) {
							r.correct = true;
							used_ans.push(x);
						}
						else {
							r.correct = false;
							r.duplicate = true;
						}
					}
				}
			}
		}

		// check for incorrect answers
		for (let r of ans_objs) {
			for (let i of question.incorrect) {
				if (i === r.value) {
					r.correct = false;
				}
			}
		}

		// return answers
		return ans_objs;
	}

	is_used(used_list: number[], check_idx: number) {
		for (let u of used_list) {
			if (check_idx === u) return true; 
		}
		return false;
	}

}
