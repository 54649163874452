import { Component, OnInit, Input, Output, EventEmitter, DoCheck } from '@angular/core';
import { Game } from '../classes/Game';
import { Round, Question } from '../classes/QSet';
import { GameService } from '../services/game.service';
import { UniqueAnswersPipe } from '../unique-answers.pipe';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {

	@Input() game: Game;
	@Input() round: Round;
	@Input() question: Question;
	@Input() q_index: number;

	@Output() err: EventEmitter<string> = new EventEmitter();
	@Output() score: EventEmitter<number> = new EventEmitter();
	@Output() preview: EventEmitter<number> = new EventEmitter();
	@Output() resume: EventEmitter<boolean> = new EventEmitter();

	pending: boolean;
	activeModal = "";

	needsReview = 0;

	answerPipe = new UniqueAnswersPipe();

	constructor(private ajax: GameService) { }

	ngOnInit(): void {
		//console.log(this.round);
	}

	ngDoCheck() {
		
	}

	reopen() {
		this.resume.emit(true)
	}

	startPreview() {
		this.preview.emit(this.q_index);
	}

	askQuestion() {
		if (this.question.releasing) return;
		this.pending = true;
		this.question.releasing = true;
		let obs = this.ajax.askQuestion(
			this.game.gameid, 
			this.round.round_id, 
			this.question.question_id)
			.pipe(finalize(() =>{
				this.pending = false;
				this.question.releasing = false;
			}))
			.subscribe(
				(res: any) => {},
				err => this.err.emit("Failed to release question!"))
	}

	recallQuestion() {
		if (this.question.releasing) return;
		this.pending = true;
		this.question.releasing = true;
		this.activeModal = "";
		let removed_index = this.game.removeAsked(this.round.round_id, this.question.question_id);
		let obs = this.ajax.recallQuestion(
			this.game.gameid, 
			this.round.round_id, 
			this.question.question_id)
			.pipe(finalize(() => {
				this.pending = false;
				this.question.releasing = false;
			}))
			.subscribe((res: any) => {
				if (!res.recalled) {
					this.game.addAsked(this.round.round_id, this.question.question_id, removed_index);
				}
				this.game.answered['r_'+this.round.round_id] = {};
			},
			err => {
				this.game.addAsked(this.round.round_id, this.question.question_id, removed_index);
				this.err.emit("Failed to recall question!");
			})
	}

	openScores() {
		// console.log("Show me the scores for " + this.q_index);
		this.score.emit(this.q_index);
	}

	openPreview() {
		this.preview.emit(this.q_index);
	}

	numUnscored() {
		let ans: any = this.answerPipe.transform(this.game, this.question, this.round.round_id, this.q_index);
		let count = 0;
		for (let a of ans) {
			if (a.correct === null) count++;
		}
		this.needsReview = count;
		return count;
	}

	releasable() {
		if (this.game.round_index !== this.round.round_id) return false;
		if (this.round.handout) return false;
		if (this.round.single_answer) {
			let asked = this.game.askedThisRound(this.round.round_id);
			if (!asked) asked = [];
			let modifier = this.game.submissions_open ? -1 : 0;
			if (asked && this.q_index !== asked.length + modifier) return false;
		}
		else {
			if (this.game.isAsked(this.round.round_id, this.question.question_id)) return false;
			if (!this.game.questionsLeft(this.round.round_id)) return false;
		}
		return true;
	}

	recallable() {
		if (this.game.round_index !== this.round.round_id) return false;
		if (!this.game.isAsked(this.round.round_id, this.question.question_id)) return false;
		if (this.round.single_answer) return false;
		return true;
	}
}
