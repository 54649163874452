import { Pipe, PipeTransform } from '@angular/core';
import { GameStub } from './classes/Game';

@Pipe({
  name: 'gameSearch'
})
export class GameSearchPipe implements PipeTransform {

	transform(games: GameStub[], search: string): unknown {
		if (!search) return games;

		search = search.toLowerCase();

		let out = [];
		
		for (let g of games) {
			let include = false;
			let venue = g.venue.toLowerCase();
			let title = g.title.toLowerCase();
			if (title.match(search)) include = true;
			else if (venue.match(search)) include = true;
			else if (g.hosts && g.hosts.length) {
				for (let h of g.hosts) {
					let name = h.name.toLowerCase();
					if (name.match(search)) {
						include = true;
						break;
					}
				}
			}
			if (include) out.push(g);
		}

		return out;
	}

}
