import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.css"],
})
export class AuthComponent implements OnInit {
  @Output() login: EventEmitter<any> = new EventEmitter();

  username: string;
  password: string;
  honeypot: string;

  loading: boolean;
  err = "";
  successMessage = "";

  submitting: boolean = false;

  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit(): void {
    let token = this.auth.getToken();
    if (token) {
      this.router.navigate(["/"]);
    }
  }

  goToForgotPassword() {
    this.router.navigate(["/forgot-password"]);
  }

  submitCredentials() {
    this.submitting = true;
    this.err = "";
    if (!this.username) {
      this.err = "Please enter your email address!";
      return;
    }
    if (!this.password) {
      this.err = "Please enter your password!";
      return;
    }
    this.loading = true;
    let obs = this.auth.getHostToken(this.username, this.password, this.honeypot).subscribe(
      (res: any) => {
        if (res?.status === "set-password") {
          this.err = res?.message;
        }

        if (res?.access_token) {
          this.auth.setToken(res.access_token);
          this.loading = false;
          window.location.href = "/";
        }
      },
      (err) => {
        this.err = err.error.detail;
        this.loading = false;
      }
    );

    this.submitting = false;
  }
}
