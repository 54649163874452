import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-basic-modal',
  templateUrl: './basic-modal.component.html',
  styleUrls: ['./basic-modal.component.css']
})
export class BasicModalComponent implements OnInit {

	@Input() title: string;
	@Input() message: string;
	@Input() context: any = {};
	@Input() template: TemplateRef<any>;
	@Input() action: string;
	@Input() cancel: string = "Cancel";
	@Input() hideX: boolean;
	@Input() staticBackground: boolean;

	@Output() doAction: EventEmitter<boolean> = new EventEmitter();
	@Output() doCancel: EventEmitter<boolean> = new EventEmitter();

	constructor() { }

	ngOnInit(): void {

	}

	emitAction() {
		this.doAction.emit(true);
	}

	emitCancel() {
		this.doCancel.emit(true);
	}

}
