import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Round, Question } from '../classes/QSet';
import { Game } from '../classes/Game';
import { GameService } from '../services/game.service';
import { finalize } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-score-answer',
  templateUrl: './score-answer.component.html',
  styleUrls: ['./score-answer.component.css']
})
export class ScoreAnswerComponent implements OnInit {

	@Input() uv: ScoredAnswer;
	@Input() question: Question;
	@Input() game: Game;
	@Input() round: Round
	@Input() viewOnly: boolean;

	@Output() addVariant: EventEmitter<{answer: string, index: number}> = new EventEmitter()
	@Output() addIncorrect: EventEmitter<string> = new EventEmitter()
	@Output() reset: EventEmitter<boolean> = new EventEmitter()

	chooseAnswer: boolean;

	err: string = "";

	updating = false;

	classObj: BehaviorSubject<ClassTriggers>;

	displayValue;

	constructor(private gameServe: GameService, private cdRef: ChangeDetectorRef) { }

	ngOnInit(): void {
		this.classObj = new BehaviorSubject(this.setClassObj());
		this.displayValue = this.uv.value.replace(/\\"/g, '"');
	}

	ngOnDestroy() {
		for (let a of this.question.answers) {
			a.updating = false;
		}
	}

	checkChooseAnswer() {
		console.log(this.chooseAnswer);
	}

	setClassObj() {
		let obj = {
			correct: this.uv.correct,
			wrong: this.uv.correct === false,
			blank: !this.uv.value,
			duplicate: this.uv.duplicate
		}
		return obj;
	}

	startMarkCorrect(answer: any, a_idx: number = 0) {
		if (answer.correct) return;
		else this.markCorrect(answer, a_idx);
	}

	markCorrect(answer: any, a_idx: number = 0) {
		if (!answer.value || this.updating) return;
		// add variant to answer
		this.question.answers[a_idx].updating = true;
		this.updating = true;
		this.err = "";
		this.chooseAnswer = false;
		// save variant to back end
		this.gameServe.addVariant(
			this.game.gameid, 
			this.round.round_id,
			this.question.question_id, 
			answer.value, a_idx)
			.pipe(finalize(() => {
				this.updating = false;
			}))
			.subscribe((res:any) => {
				this.uv.correct = true;
				this.classObj.next(this.setClassObj());
				this.uv.answer = this.question.answers[a_idx];
				this.uv.answer.updating = false;
				this.question.updateVariant({value: answer, a_idx})
				this.cdRef.detectChanges();
				this.reset.emit(true);
			},
			err => {
				this.question.answers[a_idx].updating = false;
				this.err = "Something went wrong. Please try again!";
				console.log(err.error.detail)
			})
	}

	markWrong(answer: any) {
		if (answer.correct === false || !answer.value) return;
		if (this.updating) return;
		if (this.uv.answer) {
			this.uv.answer.updating = true;
		}
		this.updating = true;
		this.err = "";
		this.gameServe.addIncorrect(
			this.game.gameid, 
			this.round.round_id,
			this.question.question_id, 
			answer.value)
			.pipe(finalize(() => {
				this.updating = false;
				if (this.uv.answer) {
					this.uv.answer.updating = false;
				}
			}))
			.subscribe((res:any) => {
				this.uv.correct = false;
				this.classObj.next(this.setClassObj());
				if (this.uv.answer) {
					this.uv.answer.updating = false;
				}
				this.question.updateVariant({value: answer, is_incorrect: true})
				this.uv.answer = undefined;
				this.cdRef.detectChanges();
				this.reset.emit(true);
			},
			err => {
				this.err = "Something went wrong. Please try again!";
				console.log(err.error.detail)
			})
	}

}

type ClassTriggers = {
	correct?: boolean,
	wrong?: boolean,
	blank?: boolean,
	duplicate?: boolean
}
