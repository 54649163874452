<div class="routed-container">
	<div [hidden]="loading" class="routed-content">
		<div class="std-p">
			<h2 class="center-text">Get Help!</h2>
			<h3>For urgent questions about a game in progress: text or call (805) 380-8371.</h3>
			<hr>
			<p>For support requests that are not time sensitive, use the form below to tell us about your issue:</p>
			<div id="hsform"></div>
		</div>
	</div>
	<h2 *ngIf="loading" class="center-text">One Moment...</h2>
</div>