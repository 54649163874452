
<div *ngIf="mode === ''; else editShift">
	<ng-container *ngIf="shift.status !== 'live'; else liveShift">
		<div class="game-recap-shift--header">
			<h5 class="game-recap-shift--start-end">Start/End</h5>
			<h5 class="game-recap-shift--description">Description</h5>
			<h5 class="game-recap-shift--hours">Hours</h5>
		</div>
		<div *ngFor="let wb of shift.work" class="game-recap-shift--row">
			<div class="game-recap-shift--start-end">
				<app-date-display-edit 
					[origDate]="wb.start_work"
					[state]="'view'"
					[timeOnly]="true"></app-date-display-edit> - 
				<app-date-display-edit 
					[origDate]="wb.end_work"
					[state]="'view'"
					[timeOnly]="true"></app-date-display-edit>
			</div>
			<div class="game-recap-shift--description">{{wb.description}}</div>
			<div class="game-recap-shift--hours">{{formatTimeBetween(wb.start_work, wb.end_work)}}</div>
		</div>
		<div class="game-recap-shift--total">
			<div>
				<span>{{totalTimeStr}}</span>
				<i *ngIf="totalTimeMins > times.expected_mins + 60" class="material-icons input-warn">warning</i>
			</div>
		</div>
	</ng-container>
</div>

<ng-template #editShift>
	<ul *ngIf="verifyMsgs.length" class="error-list">
		<li *ngFor="let m of verifyMsgs">{{m}}</li>
	</ul>
	<div *ngFor="let t of times.work; let i = index" class="pos-rel" [ngClass]="!i ? 'game-recap-shift--first-time' : ''">
		<div *ngIf="!t.markDelete" class="game-recap-shift--row">
			<h3 class="game-recap-shift--time-label">{{i ? 'Additional Time ' + i : 'Time Worked at Venue'}}</h3>
			<div class="flex-growable sm-px">
				<label class="pill-box">Work Start</label>
				<app-date-display-edit 
					[origDate]="t.start_work"
					[state]="'edit'"
					[error]="t.startError"
					(edited)="newTime(i, 'start_work', $event)"></app-date-display-edit>
			</div>
			<div class="flex-growable sm-px">
				<label class="pill-box">Work End</label>
				<app-date-display-edit 
					[origDate]="t.end_work"
					[state]="'edit'"
					[error]="t.endError"
					(edited)="newTime(i, 'end_work', $event)"></app-date-display-edit>
			</div>
			<div class="flex-growable" *ngIf="i">
				<label class="pill-box">Work Description</label>
				<input class="pill-box w-100" type="text" [(ngModel)]="t.description" (focusout)="t.descError = t.description ? false : true" [ngClass]="t.descError ? 'input-error' : ''">
			</div>
			<div *ngIf="i" class="game-recap-shift--delete">
				<i class="material-icons" (click)="removeWorkBlock(i)">delete</i>
			</div>
		</div>
	</div>
	<div class="game-recap-shift--add-work">
		<button class="recap-ctrl-blue" (click)="addWorkBlock()">I did additional work outside the venue</button>
	</div>
	<h1 class="center-text game-recap-shift--total-time">
		<span>Shift Total: {{totalTimeStr}}</span>
		<i *ngIf="totalTimeMins > times.expected_mins + 60" class="material-icons input-warn">warning</i>
	</h1>
	<div *ngIf="mode === 'verify' && !confirmedTime" class="std-mb">
		<button class="recap-ctrl-red" (click)="confirmTimes()">I confirm my time worked for this show to be {{formatMins(totalTimeMins, 'H')}} hours and {{totalTimeMins % 60}} minutes.</button>
	</div>
	<div class="game-recap-shift--ctrls">
		<button class="recap-ctrl-red" (click)="cancel()">Cancel</button>
		<button [ngClass]="mode === 'verify' && !confirmedTime ? 'recap-ctrl-grey' : 'recap-ctrl-green'" (click)="save()">Save</button>
	</div>
</ng-template>

<ng-template #liveShift>
	<div class="game-recap-shift--live-shift">
		<h3>
			<span>Shift active since </span>
			<app-date-display-edit
				[origDate]="shift.work[0].start_work"
				[state]="'view'"
				[timeOnly]="true"></app-date-display-edit>
			<span>.</span>
		</h3>
		<button class="recap-ctrl-blue" (click)="endShift()">End Shift</button>
	</div>
</ng-template>