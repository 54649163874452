import { Component, OnInit } from '@angular/core';
import { RecapStub } from '../classes/RecapStub';
import { RecapService } from '../services/recap.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-recaps',
  templateUrl: './recaps.component.html',
  styleUrls: ['./recaps.component.css']
})
export class RecapsComponent implements OnInit {

	recaps: RecapStub[];

	loading = true;
	err = '';

	constructor(private recapServe: RecapService) { }

	ngOnInit(): void {
		this.getRecaps();
	}

	getRecaps() {
		this.recapServe.getRecapList()
			.pipe(finalize(() => this.loading = false))
			.subscribe((res: RecapStub[]) => this.recaps = res,
				err => this.err = err.error.detail)
	}

}
