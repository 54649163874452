import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../classes/User';
import { Game } from '../classes/Game';
import { ShiftService } from '../services/shift.service';
import { finalize } from 'rxjs/operators';

@Component({
	selector: 'app-admin-game-controls',
	templateUrl: './admin-game-controls.component.html',
	styleUrls: ['./admin-game-controls.component.css']
})
export class AdminGameControlsComponent implements OnInit {

	@Input() game: Game
	@Input() user: User

	@Output() reloadGame: EventEmitter<boolean> = new EventEmitter();

	err = "";

	expanded: number;
	controlVis: number;

	loading = true;

	shifts: Shift[] = []

	constructor(
		private shiftServe: ShiftService) { }

	ngOnInit(): void {
		this.getHosts()
	}

	getHosts() {
		this.loading = true;
		this.shiftServe.getGameShifts(this.game.gameid)
			.pipe(finalize(() => this.loading = false))
			.subscribe(
				(res: Shift[]) => this.shifts = res,
				err => this.err = err.error.detail)
	}

	expandHost(index: number) {
		if (this.expanded === index) {
			this.expanded = null;
		}
		else this.expanded = index;
	}

	userNotHost() {
		for (let h of this.shifts) {
			if (h.user.employee_id === this.user.employee_id.toString()) return false;
		}
		return true;
	}


	passReload() {
		this.reloadGame.emit(true);
	}

}
