import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalTimeService {

	constructor() { }

	dateStringToLocalTime(date: string): LocalTime {
		if (!date) {
			return {
				date: '',
				time: '',
				offset: this.utcOffsetString(new Date().getTimezoneOffset())
			}
		}

		let d = new Date(date);
		
		if (!d || !(d instanceof Date)) {
			d = new Date();
		}

		const localOffset = d.getTimezoneOffset();

		// get date
		let localDate = d.toLocaleDateString();
		let dateParts = localDate.split('/');
		dateParts[0] = dateParts[0].length < 2 ? `0${dateParts[0]}` : dateParts[0]
		dateParts[1] = dateParts[1].length < 2 ? `0${dateParts[1]}` : dateParts[1]

		// get time
		let localTime = d.toLocaleTimeString();
		localTime = localTime.length < 11 ? `0${localTime}` : localTime
		let hrval = parseInt(localTime.substring(0,2), 10)
		let min = localTime.substring(3,5)
		let ampm = localTime.substring(9)
		if (ampm === 'PM' && hrval < 12) hrval = hrval + 12;
		else if (ampm === 'AM' && hrval > 11) hrval = hrval - 12;
		let hr = hrval < 10 ? `0${hrval}` : hrval;

		return {
			date: `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`, 
			time: `${hr}:${min}`, 
			offset: this.utcOffsetString(localOffset)
		}
	}

	utcOffsetString(offset: number) {
		let hrOffsetVal = Math.floor(Math.abs(offset) / 60);
		let minOffsetVal = Math.floor(Math.abs(offset) - (hrOffsetVal * 60))
		let offsetOper = offset < 0 ? '+' : '-';
		let hrOffset = hrOffsetVal < 10 ? `0${hrOffsetVal}` : hrOffsetVal.toString();
		let minOffset = minOffsetVal < 10 ? `0${minOffsetVal}` : minOffsetVal.toString();

		return `${offsetOper}${hrOffset}:${minOffset}`;
	}

	localTimeToIso8601(time: LocalTime) {
		if (!time || !time.date || !time.time) return null;
		return `${time.date}T${time.time}:00${time.offset}`;
	}

	// expects strings in ISO 8601 format
	compareDatetimes(date1: string, date2: string, max_ms_diff: number = 64800000) {
		const timestamp1 = new Date(date1).getTime()
    const timestamp2 = new Date(date2).getTime()
    const ms_diff = Math.abs(timestamp1 - timestamp2);

    return ms_diff <= max_ms_diff;
	}

}
