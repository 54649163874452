import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.css']
})
export class ImageCarouselComponent implements OnInit {

	@Input() images: GameImage[] = [];
	
	nextIndex: number;
	currentIndex: number;
	prevIndex: number;

	displayImages: GameImage[] = [];

	constructor() { }

	ngOnInit(): void {
		if (this.images.length) this.incrementIdx();
	}

	incrementIdx() {
		if (this.currentIndex === undefined) this.currentIndex = 0;
		else this.currentIndex++;
		if (this.currentIndex > this.images.length - 1) this.currentIndex = 0;
		if (this.nextIndex === undefined) this.nextIndex = this.currentIndex + 1;
		else this.nextIndex++;
		if (this.prevIndex === undefined) {
			this.prevIndex = this.currentIndex - 1;
			if (this.prevIndex < 0) this.prevIndex = this.images.length - 1;
		}
		else this.prevIndex++;
		if (this.nextIndex > this.images.length - 1) this.nextIndex = 0;
		if (this.prevIndex > this.images.length - 1) this.prevIndex = 0;
		this.changeDisplayImages()

	}

	decrementIdx() {
		this.currentIndex--;
		this.nextIndex--;
		this.prevIndex--;
		if (this.currentIndex < 0) this.currentIndex = this.images.length - 1;
		if (this.nextIndex < 0) this.nextIndex = this.images.length - 1;
		if (this.prevIndex < 0) this.prevIndex = this.images.length - 1;
		this.changeDisplayImages()
	}

	changeDisplayImages() {
		this.displayImages = [
			this.images[this.prevIndex],
			this.images[this.currentIndex],
			this.images[this.nextIndex]
		];
	}

}
