import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { User } from '../classes/User';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tech-support',
  templateUrl: './tech-support.component.html',
  styleUrls: ['./tech-support.component.css']
})
export class TechSupportComponent implements OnInit {

	user: User;
	venue_name: string;
	event_date: string;
	loading = true;

  emailField: string = "";
  nameField: string = "";
  venueField: string = "";

	constructor(private authServ: AuthService, private router: Router, private route: ActivatedRoute) { }


  ngOnInit(): void {
  	this.route.queryParams.subscribe((params) => {
  		this.venue_name = params["venuename"];
  		this.authServ.getHostDetail()
				.subscribe((res: any) => {
					this.user = new User(res);
					if( window.hasOwnProperty("hbspt") ){
            window.hbspt.forms.create({
              portalId: "8996927",
              formId: "269b34a3-db3e-425d-aeec-b374d60ca2d8",
              target: "#hsform",
              onFormReady: (form) => {
                setTimeout(this.autofillHSForm.bind(this), 200, form);
              }
            })
          }
					this.loading = false;
				})
  	})

  }

  autofillHSForm(form) {
  	if (this.user) {
  		this.emailField = form.querySelector('input[name="email"]');
	    setTimeout(this.fillField.bind(this), 200, "emailField", this.user.email);
	    this.nameField = form.querySelector('input[name="TICKET.first_name"]');
	    setTimeout(this.fillField.bind(this), 200, "nameField", this.user.shortName());
	    if (this.venue_name) {
				this.venueField = form.querySelector('input[name="TICKET.venue_name"]');
				setTimeout(this.fillField.bind(this), 200, "venueField", this.venue_name);
	    }
  	}
  }

  fillField(fieldName, value) {
  	let field = this[fieldName];
  	if (field) field.value = value;
  }

}
