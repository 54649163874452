<div *ngIf="mode !== 'verify'; else editStats">
	<div *ngIf="!mode; else editStats">
		<div *ngIf="!shift.no_show" class="game-recap-stats--stat-box">
			<div class="game-recap-stats--times">
				<h5>Start/End Time</h5>
				<app-date-display-edit 
					[origDate]="shift.game_start ? shift.game_start : game.first_action" 
					[state]="'view'"></app-date-display-edit> - 
				<app-date-display-edit 
					[origDate]="shift.game_end ? shift.game_end : game.last_action"
					[state]="'view'"></app-date-display-edit>
			</div>
			<div class="game-recap-stats--players">
				<h5>
					<span>Player Count</span>
					<i *ngIf="stats.total_players === null || stats.total_players === undefined" class="material-icons input-error">warning</i>
				</h5>
				<span>{{stats.total_players}}</span>
			</div>
			<div *ngIf="!game.use_external_app" class="game-recap-stats--teams">
				<h5>
					<span>Team Count</span>
					<i *ngIf="stats.qm_teams === null || stats.qm_teams === undefined" class="material-icons input-error">warning</i>
				</h5>
				<span>{{stats.qm_teams}}</span>
			</div>
			<div class="game-recap-stats--tables">
				<h5>
					<span>Empty Tables</span>
					<i *ngIf="stats.empty_tables === null || stats.empty_tables === undefined" class="material-icons input-error">warning</i>
				</h5>
				<span>{{stats.empty_tables}}</span>
			</div>
			<div class="game-recap-stats--rating">
				<h5>
					<span>Question Rating</span>
					<i *ngIf="stats.question_rating === null || stats.question_rating === undefined" class="material-icons input-error">warning</i>
				</h5>
				<span>{{stats.question_rating}}</span>
			</div>
		</div>
		<h5>
			<span>Internal Notes</span>
			<i *ngIf="shift.no_show && !shift.internal_notes" class="material-icons input-error">warning</i>
		</h5>
		<p>{{stats.internal_notes}}</p>
	</div>
</div>

<ng-template #editStats>
	<ul *ngIf="verifyMsgs.length" class="error-list">
		<li *ngFor="let m of verifyMsgs">{{m}}</li>
	</ul>
	<div *ngIf="!shift.no_show" class="game-recap-stats--edit-form">
		<div *ngIf="!shift.no_show" class="game-recap-stats--datetime">
			<label class="pill-box">Game Started</label>
			<app-date-display-edit 
				[origDate]="stats.game_start" 
				[state]="'edit'"
				(edited)="stats.game_start = $event"></app-date-display-edit>
		</div>
		<div *ngIf="!shift.no_show" class="game-recap-stats--datetime">
			<label class="pill-box">Game Ended</label>
			<app-date-display-edit 
				[disable]="game.ended ? false : true"
				[origDate]="stats.game_end" 
				[state]="'edit'"
				(edited)="stats.game_end = $event"></app-date-display-edit>
		</div>
		<div *ngIf="!shift.no_show" class="game-recap-stats--numbers">
			<label class="pill-box">Player Count</label>
			<input class="pill-box" type="number" [(ngModel)]="stats.total_players" [ngClass]="stats.total_players === null || stats.total_players === undefined ? 'input-error' : ''">
		</div>
		<div *ngIf="!shift.no_show && !game.use_external_app" class="game-recap-stats--numbers">
			<label class="pill-box">Team Count</label>
			<input class="pill-box" type="number" [(ngModel)]="stats.qm_teams" [ngClass]="stats.qm_teams === null || stats.qm_teams === undefined ? 'input-error' : ''">
		</div>
		<div *ngIf="!shift.no_show" class="game-recap-stats--numbers">
			<label class="pill-box">Empty Tables</label>
			<input class="pill-box" type="number" [(ngModel)]="stats.empty_tables" [ngClass]="stats.empty_tables === null || stats.empty_tables === undefined ? 'input-error' : ''">
		</div>
		<div *ngIf="!shift.no_show" class="game-recap-stats--select">
			<label class="pill-box">Question Rating</label>
			<select class="pill-box" (change)="setQuestionRating($event.target.value)" [ngClass]="!stats.question_rating ? 'input-error' : ''">
				<option hidden [selected]="!stats.question_rating"></option>
				<option [selected]="stats.question_rating === 5" value="5">5--Awesome!</option>
				<option [selected]="stats.question_rating === 4" value="4">4</option>
				<option [selected]="stats.question_rating === 3" value="3">3</option>
				<option [selected]="stats.question_rating === 2" value="2">2</option>
				<option [selected]="stats.question_rating === 1" value="1">1--Lousy</option>
			</select>
		</div>
	</div>
	<div class="game-recap-stats--notes">
		<label class="pill-box">Internal Notes</label>
		<textarea class="pill-box" [(ngModel)]="stats.internal_notes" [ngClass]="shift.no_show && !stats.internal_notes ? 'input-error' : ''"></textarea>
	</div>
	<div class="game-recap-stats--controls">
		<button class="recap-ctrl-red" (click)="cancel()">Cancel</button>
		<button class="recap-ctrl-green" (click)="save()">Save</button>
	</div>
</ng-template>
