import { Component, OnInit, Input } from '@angular/core';
import { RecapStub } from '../classes/RecapStub';

@Component({
  selector: 'app-recap-stub',
  templateUrl: './recap-stub.component.html',
  styleUrls: ['./recap-stub.component.css']
})
export class RecapStubComponent implements OnInit {

	@Input() stub: RecapStub;

	constructor() { }

	ngOnInit(): void {
		//console.log(this.stub.is_live)
	}

}
