export class QSet {
	set_id: number;
	download_link: string;
	slide_link: string;
	slide_password: string;
	rounds: Round[] = [];

	constructor(qs: any) {
		if (qs.set_id) this.set_id = (typeof qs.set_id === "number") ? qs.set_id : parseInt(qs.set_id, 10);
		this.download_link = qs.download_link;
		this.slide_link = qs.slide_link;
		this.slide_password = qs.slide_password;
		if (qs.rounds && Array.isArray(qs.rounds)) {
			for (let r of qs.rounds) { 
				this.rounds.push(new Round(r)); 
			}
		}
	}
}

export class Round {
	round_id: number;
	round_order: number;
	title: string;
	subtitle: string;
	num_questions: number;
	double_or_nothing: boolean;
	single_answer: boolean;
	handout: boolean;
	matches: string[];
	questions: Question[] = [];
	tiebreaker: boolean;
	player_instructions: string;
	announcement: string;
	asked: any[] = [];

	constructor(rnd: any) {
		if (rnd.round_id) this.round_id = (typeof rnd.round_id === "number") ? rnd.round_id : parseInt(rnd.round_id, 10);
		this.round_order = (typeof rnd.round_order === "number") ? rnd.round_order : parseInt(rnd.round_order, 10);
		this.title = rnd.title;
		this.subtitle = rnd.subtitle;
		this.player_instructions = rnd.player_instructions;
		if (rnd.num_questions) this.num_questions = (typeof rnd.num_questions === "number") ? rnd.num_questions : parseInt(rnd.num_questions, 10);
		this.double_or_nothing = (rnd.double_or_nothing);
		this.single_answer = (rnd.single_answer);
		this.tiebreaker = (rnd.tiebreaker);
		this.handout = (rnd.handout);
		this.matches = rnd.matches;
		this.announcement = rnd.announcement;
		if (rnd.questions && Array.isArray(rnd.questions)) {
			for (let q of rnd.questions) {
				this.questions.push(new Question(q));
			}
		}
	}

	orderByAsked(asked: any[]) {
		let qlist: Question[] = [];
		if (!asked) asked = [];
		this.asked = asked;
		if (asked.length) {
			for (let a of asked) {
				for (let q of this.questions) {
					if (a.question_id === q.question_id) {
						qlist.push(q);
					}
				}
			}
		}
		let unasked = [];
		for (let q of this.questions) {
			let include = true;
			for (let a of asked) {
				if (a.question_id === q.question_id) include = false;
			}
			if (include) unasked.push(q);
		}
		unasked.sort((a, b) => {
			if (a.question_order > b.question_order) return 1;
			else if (a.question_order < b.question_order) return -1;
			else return 0;
		})
		this.questions = qlist.concat(unasked);
	}
}

export type Source = {
	source_id: number;
	question_id: number;
	source_link: string;
}

export class Question {
	question_id: number;
	question_order: number;
	question_text: string;
	question_note: string;
	num_answers: number;
	score: number;
	incorrect_score: number;
	answers: Answer[];
	incorrect: string[];
	image: string;
	sources: Source[];
	releasing?: boolean;
	scoring_queue: QueuedScoringChange[] = [];

	constructor(q: any) {
		if (q.question_id) this.question_id = (typeof q.question_id === "number") ? q.question_id : parseInt(q.question_id, 10);
		this.question_order = (typeof q.question_order === "number") ? q.question_order : parseInt(q.question_order, 10);
		this.question_text = q.question_text;
		this.question_note = q.question_note;
		if (q.num_answers) this.num_answers = (typeof q.num_answers === "number") ? q.num_answers : parseInt(q.num_answers, 10);
		if (q.score !== null && q.score !== undefined) {
			this.score = (typeof q.score === "number") ? q.score : parseInt(q.score, 10);
		} 
		if (q.incorrect_score !== null && q.incorrect_score !== undefined) {
			this.incorrect_score = (typeof q.incorrect_score === "number") ? q.incorrect_score : parseInt(q.incorrect_score, 10);
		}
		this.incorrect = q.incorrect;
		this.image = q.image;
		this.sources = q.sources;
		this.answers = q.answers;
	}

	isUpdating() {
		for (let a of this.answers) {
			if (a.updating) return true;
		}
		return false;
	}

	queueUpdateVariant(update: QueuedScoringChange) {
		this.scoring_queue.push(update);
		if (this.isUpdating()) return false;
		else {
			this.processQueue();
			return true;
		}
	}

	processQueue() {
		for (let q of this.scoring_queue) {
			this.updateVariant(q);
		}
	}

	updateVariant(update: QueuedScoringChange) {
		if (update.is_incorrect) {
			this.removeVariant(update.value);
			this.addIncorrect(update.value);
		}
		else {
			this.removeIncorrect(update.value);
			this.removeVariant(update.value);
			this.addVariant(update.a_idx, update.value);
		}
	}

	addVariant(a_idx: number, value: string) {
		if (!a_idx) a_idx = 0;
		this.answers[a_idx].variations.push(value)
	}

	removeVariant(value: string) {
		for (let a of this.answers) {
			for (let i = a.variations.length - 1; i >= 0; i--) {
				if (a.variations[i] === value) {
					a.variations.splice(i, 1);
				}
			}
		}
	}

	addIncorrect(value: string) {
		let found = false;
		for (let i of this.incorrect) {
			if (i === value) found = true;
		}
		if (!found) this.incorrect.push(value);
	}

	removeIncorrect(value: string) {
		for (let i = this.incorrect.length - 1; i >= 0; i--) {
			if (this.incorrect[i] === value) {
				this.incorrect.splice(i, 1);
			}
		}
	}
}