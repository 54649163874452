import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FinalScore } from '../classes/Game';

@Injectable({
  providedIn: 'root'
})
export class RecapService {
	
	rootUri = environment.root_uri;

	constructor(private http: HttpClient) { }

	getRecapList() {
		return this.http.get(this.rootUri + "/api/host/game/recaps");
	}

	updateRecap(gameid: string, recap: Recap) {
		return this.http.post(`${this.rootUri}/api/host/game/${gameid}/recap/update`, recap)
	}

	endShift(gameid: string, recap: Recap, local_work_start: string, local_work_end: string, minutes_working: number) {
		let postBody = {recap, local_work_start, local_work_end, minutes_working}
		return this.http.post(`${this.rootUri}/api/host/game/${gameid}/end-shift`, postBody);
	}

	setWorkTimes(gameid: string, hostid: string, start: string, end: string) {
		let postBody = {start, end}
		return this.http.post(`${this.rootUri}/api/admin/game/${gameid}/host/${hostid}/edit-work-times`, postBody);
	}

	reopenShift(gameid: string, hostid: string) {
		return this.http.post(`${this.rootUri}/api/admin/game/${gameid}/host/${hostid}/reopen-shift`, {});
	}

	getGameImages(gameid: string) {
		return this.http.get(`${this.rootUri}/api/recap/${gameid}/images`);
	}

	blockImage(gameid: string, image_code: string, block_reason: string, block_player: boolean = false) {
		return this.http.post(`${this.rootUri}/api/recap/${gameid}/image/${image_code}/block`, {block_reason, block_player});
	}

	prepFile(file: File) {
		let data = new FormData();
		data.append('image', file);
		return data;
	}

	uploadImage(id: string, image: File, position: number = null) {
		let headers = new HttpHeaders({'enctype': 'multipart/form-data'});
		return this.http.post(
			`${this.rootUri}/api/recap/${id}/image/upload`,
			this.prepFile(image),
			{ 
				headers,
				params: {
					position: position.toString()
				}
			})
	}

	setImageApproval(gameid: string, image_code: string, approval: boolean, position: number = 0) {
		let pos = position !== null && position !== undefined ? position.toString() : "";
		return this.http.post(
			`${this.rootUri}/api/recap/${gameid}/image/${image_code}/approval`,
			{ approval, position: pos });
	}

	updateFinalScores(gameid: string, scores: FinalScore[]) {
		return this.http.post(`${this.rootUri}/api/recap/${gameid}/scores/update-all`, {scores});
	}
	
}
