<h2 *ngIf="loading" class="loading-message">{{loading}}</h2>

<h2 *ngIf="err" class="error-message lg-mt">
	<br><br>
	<span>{{err}}</span>
	<span *ngIf="deadWS === true">
		<br>
		<button class="basic-btn-red" (click)="resetConnection()">{{retryButtonLink ? 'Back to Home' : 'Retry'}}</button>
	</span>
</h2>

<div *ngIf="!loading && showSet" class="routed-container">
	<div class="routed-content">
		<div class="routed-bumper">
			<div *ngIf="game && game.status === 'live' && (game.shift_status === 'live' || !game.shift_status)">
				<app-round *ngFor="let r of game.question_set.rounds; let i = index" #rlist [game]="game" [round]="r"
					[expanded]="expandIdx === r.round_id" (expand)="setExpanded($event)"></app-round>
				<div class="round-list-bumper"></div>
			</div>
			<div *ngIf="game?.use_external_app">
				<p>This is a Music Bingo game. Please follow the link to our music bingo interface and log in with the credentials provided.</p>
				<h2 *ngIf="externalCredentials">
					<span>email: {{externalCredentials.email}}</span><br>
					<span>password: {{externalCredentials.password}}</span>
				</h2>
				<p><strong>Do NOT use any credentials other than the ones provided for THIS PARTICULAR SHOW.</strong></p>

				<p><strong>Additionally, <em>we strongly recommend</em> running the Bingo app in a PRIVATE/INCOGNITO TAB. Failure to follow these instructions could result in disruption of your game or of another game that is already in progress!</strong></p>
				<p>When the game is over, you can return to this interface to complete your recap.</p>
				<p class="hrz-center">
					<a href="https://docs.google.com/spreadsheets/d/1E01hF_DSysJnjE8Fs_GFBleah_m_faaT6aHj2JAjGNc/edit?usp=sharing" target="_blank"><button class="basic-btn-lgt-blue">View This Week's Playlists</button></a>
				</p>
				<p class="hrz-center">
					<a href="https://app.rockstar.bingo/login" target="_blank"><button class="basic-btn-red">Go To Music
							Bingo App</button></a>
				</p>
			</div>
		</div>
	</div>
	<div class="small-header" *ngIf="game">
		<div class="hrz-center">
			<div class="hrz-between w-100 w-max-700 clickable">
				<div class="hrz-start" (click)="activeModal = 'gameInfo'">
					<div class="vert-center md-mr">
						<i class="material-icons help-icon">help</i>
					</div>
					<div class="vert-center">
						<h3 class="no-m">{{game.niceTitle()}} ({{game.status.toUpperCase()}})</h3>
						<h4 *ngIf="!game.use_external_app" class="no-m">Join Code: {{game.code}}</h4>
					</div>
				</div>

				<div class="vert-center clickable" (click)="activeModal = 'teamList'">
					<div>
						<i class="material-icons big-icon">group</i>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="game && game.status !== 'ended' && (shift?.status === 'live' || user?.admin)"
		class="game-context-menu hrz-center" [ngClass]="showContextMenu ? '' : 'hidden-context'">
		<div class="w-100 w-max-700">
			<i class="material-icons expander clickable" (click)="showContextMenu = !showContextMenu">{{showContextMenu
				? 'expand_more' : 'expand_less'}}</i>
			<div class="hrz-between" (click)="showContextMenu = !showContextMenu">
				<h4 *ngIf="game?.last_round" class="no-m truncate-text">
					<span *ngIf="lastRound">Round {{lastRound.round.round_order + 1}}: {{lastRound.round.title}}</span>
				</h4>
				<h4 *ngIf="!game?.last_round" class="no-m">Game Not Started</h4>
				<h4 class="no-m round-status">{{roundStatus(game.round_index)}}</h4>
			</div>
			<div class="game-ctrls">
				<div (click)="roundSummary()" [ngClass]="!game?.last_round ? 'no-action' : ''">
					<i class="material-icons">view_list</i>
					<span>Summary</span>
				</div>
				<div (click)="roundAnswers()" [ngClass]="!game?.last_round ? 'no-action' : ''">
					<div class="hrz-center score-button">
						<i class="material-icons">fact_check</i>
						<div *ngIf="lastRound?.needsReview && showContextMenu" class="answer-indication vert-center">
							<div class="center-text">{{lastRound.needsReview}}</div>
						</div>
					</div>
					<span>Answers</span>
				</div>
				<div *ngIf="shift?.status === 'live' || shift?.status === 'draft'" (click)="toRecap()">
					<i class="material-icons">create</i>
					<span>Recap</span>
				</div>
				<div (click)="shift?.status === 'live' ? validateEnd('shift') : validateEnd('game')">
					<i class="material-icons">logout</i>
					<span>{{shift?.status === 'live' ? 'End Shift' : 'End Game'}}</span>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="game && game.status === 'ended'" class="hrz-center fixed-bottom std-p">
		<button class="basic-btn-lgt-blue" (click)="toRecap()">View Recap</button>
		<button *ngIf="user?.admin" class="basic-btn-red std-ml" (click)="reopenGame()">Reopen Game</button>
	</div>
</div>
<div *ngIf="recapMode && !loading" class="live-game--recap-overlay" #adjustableDiv>
	<app-game-recap *ngIf="recapMode" [user]="user" [game]="game" [shift]="shift" (recapClose)="recapMode = false"
		(shiftEnd)="validateEnd('shift')" (gameEnd)="validateEnd('game')"></app-game-recap>
</div>

<app-basic-modal *ngIf="game && activeModal === 'gameInfo'" [title]="gameInfoSlug" [template]="gmInf" [cancel]="'Done'"
	(doCancel)="activeModal = ''"></app-basic-modal>

<ng-template #gmInf>
	<h3>{{game.venue_phone}}<br>{{game.venue_address}}<br>{{game.venue_city}}{{game.venue_state ? ', ' :
		''}}{{game.venue_state}} {{game.venue_zip}}</h3>
	<h4>
		<span>Started: {{game.started}}</span>
		<span *ngIf="game.status === 'live'"><br>Currently <span class="live">LIVE</span></span>
		<span *ngIf="game.ended"><br>Ended: {{game.ended}}</span>
		<strong *ngIf="game.status === 'live' && !game.use_external_app"><br>Join Code: {{game.code}}</strong>
		<button *ngIf="game.status === 'live' && !game.use_external_app" class="sm-btn-red std-ml" (click)="changeCode()">Change Code</button>
	</h4>
	<p *ngIf="game.question_set?.slide_link" class="no-mt">
		<a href="{{game.question_set.slide_link}}" target="_blank">Slide Presentation</a>
		<span *ngIf="game.question_set.slide_password" class="md-ml">Password is
			'{{game.question_set.slide_password}}'</span>
	</p>
	<a *ngIf="game.status !== 'ended' && game.question_set?.download_link" class="sm-mb text-decoration-none"
		href={{game.question_set.download_link}} target="_blank">
		<button class="basic-btn-lgt-blue w-100-percent flex items-center justify-center">
			<i class="material-icons">download</i>
			<span>Download Set</span>
		</button>
	</a>
	<p class="support-message">
		<span>Need help? Call or text (805) 380-8371</span>
	</p>
	<app-admin-game-controls *ngIf="user && user.admin" [game]="game" [user]="user"
		(reloadGame)="getGameState(game.gameid)"></app-admin-game-controls>
</ng-template>

<app-basic-modal *ngIf="activeModal === 'teamList'" [title]="'Team Management'" [template]="teamMngmt" [cancel]="''"
	(doCancel)="activeModal = ''"></app-basic-modal>

<ng-template #teamMngmt>
	<app-team-management [game]="game" [instantUpdateFinalScore]="true"></app-team-management>
</ng-template>

<app-basic-modal *ngIf="activeModal === 'confirmEndShift'" [title]="'Ready to End Your Shift?'"
	[template]="endShiftNotice" [cancel]="'Go Back'" [action]="'End Shift'" (doCancel)="activeModal = ''"
	(doAction)="endShift()"></app-basic-modal>

<ng-template #endShiftNotice>
	<p>Ending your shift will also end your game unless there is another QM assigned to it as well. <strong>You will
			still need to complete your recap after taking this action.</strong></p>
</ng-template>

<app-basic-modal *ngIf="activeModal === 'confirmEndGame'" [title]="'Really End This Game?'"
	[message]="'This action will end the game and CANNOT BE UNDONE except by an admin!'" [cancel]="'Go Back'"
	[action]="'End Game'" (doCancel)="activeModal = ''" (doAction)="endGame()"></app-basic-modal>

<app-basic-modal *ngIf="activeModal === 'noEndWithOpenShift'" [title]="'A Shift is Still Live'"
	[message]="'One or more Hosts assigned to this game are still live. Their shifts will need to be closed before the game can be ended.'"
	[cancel]="'Go Back'" (doCancel)="activeModal = ''"></app-basic-modal>

<app-basic-modal *ngIf="activeModal === 'issueCheck'" [title]="'Checking for Issues...'" [cancel]="null"
	[staticBackground]="true" [hideX]="true"></app-basic-modal>

<app-basic-modal *ngIf="activeModal === 'noEndWithRoundOpen'" [title]="'A Round is Still Active'"
	[message]="'Please close the active round before ending the game!'" [cancel]="'Go Back'"
	(doCancel)="activeModal = ''"></app-basic-modal>

<app-basic-modal *ngIf="activeModal === 'noEndBeforeReview'" [title]="'Answers Need Review!'"
	[template]="unreviewedList" [cancel]="'Go Back'" (doCancel)="activeModal = ''"></app-basic-modal>

<ng-template #unreviewedList>
	<p>You still have some unreviewed answers for this game:</p>
	<ul>
		<li *ngFor="let r of game.needsReview">
			<strong>Round {{r.round.round_order + 1}} ({{r.needsReview}} answers needing review)</strong>
		</li>
	</ul>
	<p>The game cannot be ended until all answers are reviewed.</p>
</ng-template>

<app-basic-modal *ngIf="activeModal === 'allAnswersIn'" [title]="'All Teams Submitted'"
	[message]="'NOTE: This does not account for teams who are playing with pen and paper.'" [cancel]="'Go Back'"
	[action]="'Close Submissions'" (doCancel)="activeModal = ''" (doAction)="closeSubmissions()"></app-basic-modal>

<app-basic-modal *ngIf="activeModal === 'allQuestionsAsked'" [title]="'All Questions Released!'"
	[message]="'You have asked all the questions for this round. Don\'t forget to open submissions for your teams!'"
	[cancel]="'Go Back'" [action]="'Open Submissions'" (doCancel)="activeModal = ''"
	(doAction)="openSubmissions()"></app-basic-modal>
