import { Pipe, PipeTransform } from '@angular/core';
import { Game } from './classes/Game';
import { Question } from './classes/QSet';

@Pipe({
	name: 'uniqueAnswers'
})
export class UniqueAnswersPipe implements PipeTransform {

	transform(game: Game, question: Question, round_id: number, q_idx: number, resetter?: boolean): unknown {

		// let variations = [];
		// for (let a of question.answers) {
		// 	variations.push(a.variations);
		// }
		let answered = game.getQuestionAnswers(round_id, q_idx);

		// create an object with unique responses as keys that map to a bool (true | false | null)
		let uniques: any = {};
		for (let t of answered) {
			for (let q of t) {
				if (q) uniques[q] = null;
			}
		}

		// for each correct variation, update corresponding key to be a scored answer
		for (let a of question.answers) {
			for (let q of a.variations) {
				if (uniques[q] !== undefined) {
					uniques[q] = { value: q, correct: true, original: a.original, answer: a };
				}
			}
		}

		// for each incorrect, update corresponding key to be a scored answer
		for (let i of question.incorrect) {
			if (uniques[i] !== undefined) {
				uniques[i] = { value: i, correct: false };
			}
		}

		let output = []
		for (let u in uniques) {
			if (uniques[u] != null) output.push(uniques[u]);
			else output.push({ value: u, correct: null })
		}

		// output.sort((a, b) => {
		// 	if (a.correct === null && b.correct !== null) return -1;
		// 	else if (a.correct !== null && b.correct === null) return 1;
		// 	else if (a.correct && !b.correct) return -1;
		// 	else if (!a.correct && b.correct) return 1;
		// 	else return 0;
		// })

		return output;
	}

}
