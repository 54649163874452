import { Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, DoCheck, ViewChildren, QueryList } from '@angular/core';
import { Game } from '../classes/Game';
import { Round, Question } from '../classes/QSet';
import { GameService } from '../services/game.service';
import { QuestionComponent } from '../question/question.component';
import { UniqueAnswersPipe } from '../unique-answers.pipe';

@Component({
  selector: 'app-round',
  templateUrl: './round.component.html',
  styleUrls: ['./round.component.css']
})
export class RoundComponent implements OnInit {

	@Input() game: Game;
	@Input() round: Round;
	@Input() expanded: boolean;

	@Output() err: EventEmitter<string> = new EventEmitter();
	@Output() expand: EventEmitter<number> = new EventEmitter();

	@ViewChildren('qlist') questions: QueryList<QuestionComponent>;

	confirmRecall: boolean;

	loading: boolean;

	previewQ: QuestionComponent;
	previewIdx: number;

	scoreQIdx: number;
	scoreQ: Question;
	activeModal = "";

	needsReview = 0;
	answerPipe = new UniqueAnswersPipe();

	constructor(private ajax: GameService) { }

	ngOnInit(): void {
		// this.updateAllReviewed();
	}

	ngAfterViewChecked() {
		
	}

	ngDoCheck() {
		this.updateAllReviewed();
	}

	resetTeamSubmissions(team_code: string) {
		this.game.resetTeamSubmissionForRound(this.round.round_id, team_code);
		this.updateAllReviewed();
		this.game.resetter = !this.game.resetter;
		this.ajax.resetRoundForTeam(this.game.gameid, this.round.round_id, team_code)
			.subscribe((res:any) => {}, err => {});
	}

	updateAllReviewed() {
		this.needsReview = 0;
		let asked = this.game.askedThisRound(this.round.round_id);
		if (!asked) asked = [];
		for (let i = 0; i < asked.length; i++) {
			let q = this.game.getQuestionByAskedIndex(this.round.round_id, i);
			this.needsReview += this.updateReviewed(q, i)
		}
	}

	variantForQ(answer: {answer: string, index: number}, question: Question) {
		this.game.markCorrect(answer.answer, question.question_id, answer.index);
		this.updateAllReviewed();
	}

	incorrectForQ(incorrect: string, question: Question, q_idx: number) {
		this.game.markWrong(incorrect, question.question_id);
		this.updateAllReviewed();
	}
	

	updateReviewed(question: Question, index: number) {
		let ans: any = this.answerPipe.transform(this.game, question, this.round.round_id, index);
		let count = 0;
		for (let a of ans) {
			if (a.correct === null) count++;
		}
		return count;
	}

	numAsked() {
		let asked = this.game.askedThisRound(this.round.round_id);
		return (asked) ? asked.length : 0;
	}

	numUnreviewed() {
		let count = 0;
		for (let i of this.questions) {
			count += i.numUnscored();
		}
		return count;
	}

	validateStartRound() {
		if (this.game.round_index) {
			this.activeModal = "mustCloseRound";
			return;
		}
		let rlist = this.game.needsReview.filter((r) => r.round.round_order !== this.round.round_order);
		if (rlist.length > 0) {
			this.activeModal = 'reviewAnswers';
			return;
		}
		this.startRound();
	}

	recallPreviewedQuestion() {
		this.confirmRecall = false;
		this.previewQ.recallQuestion();
	}

	getQuestionComponent(index: number) {
		let found: QuestionComponent = undefined;
		this.questions.forEach((q, i, arr) => {
			if (i === index) found = q;
		});
		return found;
	}

	queueNextAnswer() {
		if (this.numAsked() >= this.round.num_questions) return;
		this.previewIdx = this.numAsked();
		this.previewQ = this.getQuestionComponent(this.previewIdx);
		if (this.previewQ) this.activeModal = 'previewQuestion';
	}

	queueUnreviewedAnswer() {
		let asked = this.game.askedThisRound(this.round.round_id);
		if (!asked) return;
		let index = asked.length - 1;
		let match;
		this.questions.forEach((q, i) => {
			if (q.numUnscored()) {
				if (i < index) index = i;
				match = true;
			};
		})
		if (!match) index = 0;
		this.startScoring(index);
	}

	startRound() {
		this.activeModal = ''
		this.loading = true;
		let obs = this.ajax.startRound(this.game.gameid, this.round.round_id)
			.subscribe(res => {
				this.game.round_index = this.round.round_id;
				this.loading = false;
			}, err => {
				this.err.emit("Failed to start round!");
				this.loading = false;
			})
	}

	endRound() {
		if (!this.game.round_index || this.game.submissions_open) return;
		this.loading = true;
		let obs = this.ajax.endRound(this.game.gameid, this.round.round_id)
			.subscribe(res => {
				this.game.round_index = null;
				this.loading = false;
			}, err => {
				this.err.emit("Failed to end round!");
				this.loading = false;
			})
	}

	toggleSubmissions() {
		if (this.loading) return;
		this.loading = true;
		if (this.round.handout && !this.game.submissions_open) this.openHandout();
		else if (this.game.submissions_open || this.round.single_answer) this.closeSubmissions();
		else if (!this.round.asked.length) {
			this.activeModal = "noQuestionsReleased";
			this.loading = false;
		}
		else if (this.numAsked() < this.round.num_questions) {
			this.activeModal = "askAllAnswers";
			this.loading = false;
		}
		else this.openSubmissions();
	}

	openHandout() {
		this.ajax.openHandout(this.game.gameid, this.round.round_id)
			.subscribe((res: any) => {
				this.loading = false;
			},
			err => {
				console.log(err);
				this.loading = false;
			})
	}

	openSubmissions() {
		this.activeModal = "";
		this.ajax.openSubmissions(this.game.gameid)
			.subscribe((res: any) => {
				this.loading = false;
			},
			err => {
				console.log(err);
				this.loading = false;
			})
	}

	pauseSubmissions() {
		this.closeSubmissions("yes")
	}

	closeSubmissions(keep_round: string = "") {
		this.ajax.closeSubmissions(this.game.gameid, keep_round)
			.subscribe((res: any) => {
				this.loading = false;
			},
			err => {
				console.log(err);
				this.loading = false;
			})
	}

	startPreviewQuestion(index: number) {
		this.previewIdx = index;
		this.previewQ = this.getQuestionComponent(index);
		this.activeModal = 'previewQuestion';
	}

	startScoring(index: number) {
		this.scoreQIdx = index;
		this.scoreQ = this.game.getQuestionByAskedIndex(this.round.round_id, this.scoreQIdx);
		this.activeModal = 'scoring';
	}

	nextQuestion() {
		this.scoreQIdx++;
		let r_asked = this.game.asked['r_' + this.round.round_id];
		if (this.scoreQIdx >= r_asked.length) {
			this.scoreQIdx = 0;
		}
		this.scoreQ = this.game.getQuestionByAskedIndex(this.round.round_id, this.scoreQIdx);
	}

	prevQuestion() {
		this.scoreQIdx--;
		if (this.scoreQIdx < 0) {
			let r_asked = this.game.asked['r_' + this.round.round_id];
			this.scoreQIdx = r_asked.length - 1;
		}
		this.scoreQ = this.game.getQuestionByAskedIndex(this.round.round_id, this.scoreQIdx);
	}

	nextPreview() {
		this.previewIdx++;
		if (this.previewIdx >= this.questions.length) {
			this.previewIdx = 0;
		}
		this.previewQ = this.getQuestionComponent(this.previewIdx);
	}

	prevPreview() {
		this.previewIdx--;
		if (this.previewIdx < 0) {
			this.previewIdx = this.questions.length - 1;
		}
		this.previewQ = this.getQuestionComponent(this.previewIdx);
	}

	collapseRound() {
		if (this.expanded) this.expand.emit(null);
	}

	expandRound() {
		this.expand.emit(this.expanded ? null : this.round.round_id);
	}

}
