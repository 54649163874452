import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RecapService } from '../services/recap.service';
import { Game } from '../classes/Game';
import { User } from '../classes/User';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-image-list',
  templateUrl: './image-list.component.html',
  styleUrls: ['./image-list.component.css']
})
export class ImageListComponent implements OnInit {

	@Input() game: Game;
	@Input() user: User;
	@Input() images: GameImage[] = [];

	@Output() setBusy: EventEmitter<boolean> = new EventEmitter();
	@Output() newImages: EventEmitter<GameImage[]> = new EventEmitter();

	focusImg: number = null;
	pendingBlock: boolean;
	blockReason: string = "";
	otherReason: string;
	blockPlayer: boolean;

	approved: GameImage[] = [];
	submitted: GameImage[] = [];

	err: string = "";
	loading: string = "";

	updatingApproved: boolean;

	constructor(private recapServe: RecapService) { }

	ngOnInit(): void {
		this.sortImages();
	}

	sortImages() {
		this.approved = [];
		this.submitted = [];
		for (let im of this.images) {
			if (im.status === "submitted") {
				this.submitted.push(im)
			}
			else if (im.status === "approved") {
				this.approved.push(im)
			}
		}
		this.approved.sort((a: GameImage, b: GameImage) => {
			if (a.position === undefined) {
				return b.position >= 0 ? 1 : 0;
			}
			else if (b.position === undefined) {
				return b.position >= 0 ? -1 : 0;
			}
			if (a.position > b.position) return 1;
			else if (a.position < b.position) return -1;
			else return 0;
		})
    // assign unique images to submitted
    this.images = [...new Set(this.images.map(img => img))]

	}

	unfocus() {
		this.focusImg = null;
		this.cancelBlock()
	}

	cancelBlock() {
		this.pendingBlock = false;
		this.blockReason = "";
		this.otherReason = "";
		this.blockPlayer = false;
	}

	approveImage(index: number) {
		if (this.submitted[index]) {
			if (this.approved.length < 9) {
				let sub = this.submitted[index];
				sub.pending_change = true;
				sub.status = "approved";
				sub.position = this.approved.length;
				this.approved.push(this.submitted.splice(index, 1)[0]);
				this.unfocus();
			}
		}
	}

	unapproveImage(index: number) {
		if (this.approved[index] && !this.updatingApproved) {
			this.updatingApproved = true;
			this.approved[index].pending_change = true;
			this.approved[index].status = "submitted";
			this.approved[index].position = null;
			this.submitted.push(this.approved.splice(index, 1)[0]);
			for (let i = 0; i < this.approved.length; i++) {
				let ap = this.approved[i];
				if (ap.position !== i) {
					ap.position = i;
					ap.pending_change = true;
				}
			}
			this.updatingApproved = false;
		}
	}

	blockImage(index: number) {
		this.err = "";
		this.loading = "Reporting..."
		if (!this.blockReason || (this.blockReason === 'other' && !this.otherReason)) {
			return;
		}
		else if (!this.submitted[index]) return;
		let blocked = this.submitted[index];
		blocked.status = "blocked";
		blocked.blocked_reason = this.blockReason;
		if (this.blockReason === 'other') {
			blocked.blocked_reason = `${this.blockReason}: ${this.otherReason}`
		}
		blocked.blocked_by = this.user.userid;
		this.recapServe.blockImage(
			this.game.gameid,
			blocked.image_code,
			blocked.blocked_reason,
			this.blockPlayer)
			.pipe(finalize(() => this.loading = ""))
			.subscribe(
				(res: GameImage[]) => {
					this.unfocus();
					this.sortImages();
				},
				err => this.err = err.error.detail)
	}

	prepFileUpload(e: any) {
		let files = e.target.files;
		this.setBusy.emit(true);
		this.loading = "Prepping Images..."
		this.err = "";
		let toUpload: File[] = [];
		let usedSlots = toUpload.length + this.approved.length;
		for (let i = 0; i < files.length; i++) {
			if (usedSlots >= 9) break;
			let f = files[i];
			if (f.type.match(/^image\//)) {
				if (f.size > 10485760) {
					this.err = `${f.name} is too large.`
					this.err = `${this.err} Please choose an image that 10MB or less.`
					this.loading = ""
					this.setBusy.emit(false);
					return;
				}
				toUpload.push(files[i]);
				usedSlots++;
			}else{
				this.err = `${f.name} is not an image file. Please choose only image file.`;
				this.loading = "";
				this.setBusy.emit(false);
				return;
			}
		}
		this.uploadImages(toUpload, toUpload.length, this.approved.length);
	}

	uploadImages(toUpload: File[], total: number = null, position: number = null) {
		if (total === null) total = toUpload.length;
		if (toUpload.length) {
			let current = total - toUpload.length + 1;
			this.loading = `Uploading image ${current}/${total}...`
			this.recapServe.uploadImage(this.game.gameid, toUpload.shift(), position)
				.subscribe(
					(res: GameImage[]) => {
						if (toUpload.length) {
							this.uploadImages(toUpload, total, position + 1);
						}
						else {
							let newImgs = [];
							for (let ri of res) {
								let found = false;
								for (let i of this.images) {
									if (i.image_code === ri.image_code) found = true;
								}
								if (!found) {
									this.images.push(ri);
									newImgs.push(ri);
								}
							}
							this.sortImages();
							this.newImages.emit(newImgs);
							this.setBusy.emit(false);
							this.loading = "";
							this.err = '';
						}
					},
					err => {
						this.err = err.error.detail;
						this.setBusy.emit(false);
						this.loading = '';
					})
		}
	}

}
