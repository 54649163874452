import { Component, OnInit, Input } from '@angular/core';
import { GameStub } from '../classes/Game';
import { Router } from '@angular/router';
import { GameService } from '../services/game.service';


@Component({
  selector: 'app-game-stub',
  templateUrl: './game-stub.component.html',
  styleUrls: ['./game-stub.component.css']
})
export class GameStubComponent implements OnInit {

	@Input() game: GameStub;
	@Input() admin: boolean;

	activeModal = '';
	err = '';
	loading = false;

	constructor(private router: Router, private gameServe: GameService) { }

	ngOnInit(): void {

	}

	titleConvert(title: string) {
		if (title === 'trivia' || title === 'slam') {
			return (title === 'trivia') ? 'Pub Quiz' : 'Survey Slam';
		}
		return title;
	}

	confirmShow() {
		if (this.game.status !== 'unconfirmed') return;
		this.loading = true;
		this.err = '';
		this.gameServe.confirmGame(this.game.kt_id, this.game.slot_id)
			.subscribe((res: any) => {
				this.game.status = 'scheduled';
				this.router.navigate(["game", this.game.kt_id, "detail"]);
			},
			err => {
				this.err = err.error.detail;
				this.loading = false;
			})
	}

  formatDate() {
    // Create a new Date object of the game
    const d = new Date(this.game.date);

    // options for formatting
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
      timeZone: this.game.venue_tz || undefined,
    };
    // return formatted the date
    return d.toLocaleString('en-US', options);
  }

}
