<div class="header-box">
	<div class="header-content">
		<img class="logo" src="assets/KTquizmaster.png">
		<div class="hamburger-box">
			<app-notification-bell [user]="user"></app-notification-bell>
			<div>
				<i (click)="menuOpen = true" class="material-icons">menu</i>
			</div>

		</div>


		<!-- <div class="logout-box" [ngClass]="{highlight: userHover}" (mouseenter)="userHover = true" (mouseleave)="userHover = false" (click)="activeModal = 'logoutConfirm'">
			<span><span *ngIf="userHover">Logout </span>{{user.name}}</span>
		</div> -->
	</div>
</div>
<!-- Router outlet for app component that needs the header -->
<router-outlet></router-outlet>

<app-main-menu *ngIf="menuOpen" [user]="user" (close)="closeMenu()" (nav)="navTo($event)"
	(logout)="activeModal = 'logoutConfirm'"></app-main-menu>

<div *ngIf="activeModal === 'logoutConfirm'" class="modal-background">
	<div class="click-layer" (click)="activeModal = ''"></div>
	<div class="modal-container">
		<div class="hrz-between">
			<h2>Logout {{user.name}}?</h2>
			<i class="material-icons clear clickable" (click)="activeModal = ''">clear</i>
		</div>
		<div>
			<button class="basic-btn-lgt-blue" (click)="activeModal = ''">Go Back</button>
			<button class="basic-btn-red std-ml" (click)="logoutUser()">Logout</button>
		</div>
	</div>
</div>

<div *ngIf="activeModal === 'setVenmo'" class="modal-background">
	<div class="click-layer" (click)="activeModal = ''"></div>
	<div class="modal-container">
		<div class="hrz-between">
			<h2>Tipping Update</h2>
			<i class="material-icons clear clickable" (click)="activeModal = ''">clear</i>
		</div>

		<p>Want to enable players to tip you digitally? Now you can provide them with a link to your Venmo account right
			in the app! Just go to your profile and enter your Venmo tag to enable this feature.</p>

		<p><em>Setting up your Venmo account in app does NOT require you to enter your credentials, and it does NOT give
				King Trivia any special access to your Venmo account.</em></p>

		<div>
			<button class="basic-btn-lgt-blue" (click)="closeAndDismiss()">Dismiss</button>
			<button class="basic-btn-red std-ml" (click)="closeModalAndNavTo(['profile'])">Go to Profile</button>
		</div>
	</div>
</div>
