import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Game } from '../classes/Game';
import { Team } from '../classes/Team';
import { Round } from '../classes/QSet';
import { GameService } from '../services/game.service';

@Component({
  selector: 'app-round-team-summary',
  templateUrl: './round-team-summary.component.html',
  styleUrls: ['./round-team-summary.component.css']
})
export class RoundTeamSummaryComponent implements OnInit {

	@Input() game: Game;
	@Input() round: Round;
	@Input() team: Team;
	@Input() submit: string;
	@Input() roundScore: number;
	@Input() totalScore: number;
	@Input() doubled: boolean;
	@Input() breakdown: any[];
	@Input() overrideScore: number;

	@Output() reset: EventEmitter<string> = new EventEmitter();

	collapse = true;
	resetPending: boolean;
	editingScore: boolean;

	newScore: number;
	displayScore: number;

	constructor(private gameServe: GameService) { }

	ngOnInit(): void {
		this.displayScore = (this.overrideScore !== null && this.overrideScore !== undefined) 
			? this.overrideScore : this.roundScore;
		this.newScore = this.displayScore;
	}

	startManualScoreEdit() {
		if (!this.team.manual) return;
		this.saveScore();
	}

	saveScore() {
		if (this.newScore === this.displayScore) {
			this.editingScore = false;
			return;
		}
		let scoreDiff = this.newScore - this.displayScore;
		this.overrideScore = this.newScore;
		this.displayScore = this.newScore;
		this.totalScore += scoreDiff;
		this.editingScore = false;
		this.gameServe.scoreTeamRound(this.game.gameid, this.round.round_id, this.team.team_code, this.newScore)
			.subscribe(res => {}, err => console.log(err));
	}

	resetSubmission() {
		this.reset.emit(this.team.team_code);
	}

	doubleModifier() {
		let missedOne = false;
		let score = 0;
		for (let q of this.breakdown) {
			let qScore = 0;
			for (let a of q) {
				if (a.score) qScore += a.score;
				else missedOne = true;
			}
			score += qScore;
		}
		let oper = (missedOne) ? "-" : "+";
		return `${oper}${score}`;
	}

}
