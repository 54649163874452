<div class="question-box">
	<div class="hrz-start">
		<div *ngIf="question.image">
			<div class="question-image">
				<img src="{{question.image}}">
			</div>
		</div>
		<div>
			<p class="no-m q-text">
				<strong>{{(q_index < round.num_questions || round.num_questions === 0) ? q_index + 1 : 'Extra Question'}}. {{question.question_text}}</strong><br>
				<em *ngIf="question.question_note">{{question.question_note}}</em>
			</p>
			<ul class="no-mt q-text">
				<li *ngFor="let a of question.answers">{{a.original}}</li>
			</ul>
		</div>
	</div>
	<div *ngIf="question.sources.length" class="hrz-start">
		<div class="std-mx std-mb">
			<strong>Source(s):</strong>
		</div>
		<div class="std-mb">
			<div *ngFor="let s of question.sources" class="sm-mb">
				<a href="{{s.source_link}}">{{s.source_link}}</a>
			</div>
		</div>
	</div>
	<div *ngIf="releasable()" (click)="round.single_answer ? startPreview() : askQuestion()" class="hrz-center">
		<button class="center-text basic-btn-blue release-msg" [ngClass]="question.releasing ? 'releasing' : ''">
			<i class="material-icons">campaign</i>
			<span *ngIf="round.single_answer"> Review Clue</span>
			<span *ngIf="!round.single_answer">{{question.releasing ? ' Releasing' : ' Release'}} Question</span>
		</button>
	</div>
	<div *ngIf="game.status !== 'ended' && !releasable()" class="question-ctrl">
		<div *ngIf="game.round_index === round.round_id && !round.single_answer" class="vert-center clickable">
			<div *ngIf="recallable()" (click)="activeModal = 'recall'" class="hrz-start">
				<div class="hrz-center recall-msg">
					<div class="vert-center">
						<i class="material-icons">highlight_off</i>
					</div>
					<div class="vert-center">
						<span> Recall<br class="sm-only"> {{round.single_answer ? 'Clue' : 'Question'}}</span>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="game.round_index === round.round_id && round.single_answer" class="vert-center clickable">
			<div *ngIf="!game.submissions_open && question.question_order === round.asked.length - 1" (click)="reopen()" class="hrz-start">
				<div class="hrz-start resume-msg">
					<div class="vert-center">
						<i class="material-icons">traffic</i>
					</div>
					<div class="vert-center">
						<span> Resume<br class="sm-only"> Clue</span>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="game.isAsked(round.round_id, question.question_id)" class="vert-center clickable" (click)="openScores()">
			<div class="hrz-end">
				<div class="hrz-center">
					<div class="vert-center pos-rel">
						<i class="material-icons">fact_check</i>
						<div *ngIf="numUnscored() > 0" class="vert-center unscored">
							<div class="hrz-center">
								<span>{{numUnscored()}}</span>
							</div>
						</div>
					</div>
					<div class="vert-center">
						<span> Review<br class="sm-only"> Answers</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="activeModal === 'recall'" class="modal-background">	
	<div class="click-layer" (click)="activeModal = ''"></div>
	<div class="modal-container">
		<div>
			<h2>Really recall this question?</h2>
			<i class="material-icons clear clickable" (click)="activeModal = ''">clear</i>
		</div>
		<p>Any answer sheets already submitted for this round will be reset and need to be resubmitted.</p>
		<div class="hrz-end std-mb">
			<button class="basic-btn-grey" (click)="activeModal = ''">Go Back</button>
			<button class="basic-btn-red std-ml" (click)="recallQuestion()">Recall</button>
		</div>
	</div>
</div>