import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class GameService {

	rootUri = environment.root_uri;

	constructor(private http: HttpClient) { }

	getLicense(event_id: string) {
		return this.http.post(`${this.rootUri}/api/host/game/${event_id}/license`, {});
	}

	getGameList() {
		return this.http.get(this.rootUri + "/api/host/game/list");
	}

	getGameDetail(game_id: number) {
		return this.http.post(this.rootUri + "/api/host/game/" + game_id + "/details", {});
	}

	getGameState(gameid: string) {
		return this.http.get(this.rootUri + "/api/host/game/" + gameid + "/state");
	}

	startGame(game_id: number, recaponly: boolean = false) {
		return this.http.post(this.rootUri + "/api/host/game/" + game_id + "/start", { recaponly });
	}

	endGame(gameid: string) {
		return this.http.post(this.rootUri + "/api/host/game/" + gameid + "/end", {});
	}

	changeCode(game_id: string) {
		return this.http.post(this.rootUri + "/api/host/game/" + game_id + "/new-code", {});
	}

	startRound(game_id: string, round_id: number) {
		return this.http.post(this.rootUri + "/api/host/game/" + game_id +
			"/round/" + round_id + "/start", {});
	}

	endRound(game_id: string, round_id: number) {
		return this.http.post(this.rootUri + "/api/host/game/" + game_id +
			"/round/" + round_id + "/end", {});
	}

	openSubmissions(game_id: string) {
		return this.http.post(this.rootUri + "/api/host/game/" + game_id + "/open-submit", {});
	}

	closeSubmissions(game_id: string, keep_round: string = "") {
		return this.http.post(this.rootUri + "/api/host/game/" + game_id + "/close-submit", { keep_round });
	}

	openHandout(game_id: string, round_id: number) {
		return this.http.post(this.rootUri + "/api/host/game/" + game_id + "/round/" + round_id + "/ask-all", {});
	}

	resetRoundForTeam(gameid: string, round_id: number, team_code: string) {
		return this.http.post(this.rootUri + "/api/host/game/" + gameid +
			"/round/" + round_id +
			"/team/" + team_code + "/reset", {});
	}

	askQuestion(game_id: string, round_id: number, question_id: number) {
		return this.http.post(this.rootUri + "/api/host/game/" + game_id +
			"/round/" + round_id +
			"/question/" + question_id + "/ask", {});
	}

	recallQuestion(game_id: string, round_id: number, question_id: number) {
		return this.http.post(this.rootUri + "/api/host/game/" + game_id +
			"/round/" + round_id +
			"/question/" + question_id + "/recall", {});
	}

	addVariant(game_id: string, round_id: number, question_id: number, value: string, a_idx: number) {
		return this.http.post(this.rootUri + "/api/host/game/" + game_id +
			"/round/" + round_id +
			"/question/" + question_id + "/add-variant",
			{ a_idx, value });
	}

	addIncorrect(game_id: string, round_id: number, question_id: number, value: string) {
		return this.http.post(this.rootUri + "/api/host/game/" + game_id +
			"/round/" + round_id +
			"/question/" + question_id + "/add-incorrect",
			{ value });
	}

	setCaptain(game_id: string, team_code: string, player_code: string) {
		return this.http.post(this.rootUri + "/api/host/game/" + game_id + "/team/" + team_code + "/promote-captain", { player_code });
	}

	kickTeam(game_id: string, team_code: string, keep_scores: boolean = false) {
		return this.http.post(this.rootUri + "/api/host/game/" + game_id + "/team/" + team_code + "/kick-team", { keep_scores });
	}

	addTeam(game_id: string, kt_team_id: number, team_name: string) {
		return this.http.post(this.rootUri + "/api/host/game/" + game_id + "/team/add", { team_name, kt_team_id });
	}

	scoreTeamRound(game_id: string, round_id: number, team_code: string, score?: number) {
		let postBody = (score !== null && score !== undefined) ? { score } : {};
		return this.http.post(this.rootUri + "/api/host/game/" + game_id +
			"/round/" + round_id +
			"/team/" + team_code + "/score", postBody)
	}

	listGames(from: string, to: string) {
		return this.http.get(this.rootUri + "/api/admin/list-games-between", { params: { fromDate: from, toDate: to } });
	}

	removeHost(game_id: string, employee_id: number) {
		return this.http.post(this.rootUri + "/api/admin/game/" + game_id + "/remove-host/" + employee_id, {});
	}

	addHost(game_id: string, employee_id: number) {
		return this.http.post(this.rootUri + "/api/admin/game/" + game_id + "/add-host/" + employee_id, {});
	}

	confirmGame(kt_id: number, slot_id: string) {
		return this.http.post(this.rootUri + "/api/host/game/" + kt_id + "/slot/" + slot_id + "/confirm", {});
	}

	reopenGame(gameid: string) {
		return this.http.post(`${this.rootUri}/api/admin/game/${gameid}/restart`, {});
	}

}

export interface SaveResult {
	status: 'success' | 'error';
	message: string;
}
