import { Component, OnInit, HostListener, Input } from '@angular/core';
import { NotificationService } from '../services/notification.service';
import { finalize } from 'rxjs/operators';
import { User } from '../classes/User';

@Component({
  selector: 'app-notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls: ['./notification-bell.component.css']
})
export class NotificationBellComponent implements OnInit {

  @Input() user: User;
  isDropdownOpen: boolean = false;
  unreadNotifications: HostNotification[] = [];
  readNotifications: HostNotification[] = [];

  fetching: boolean = false
  showNotification: boolean = false
  selectedNotification: HostNotification

  constructor(private notifService: NotificationService) { }

  ngOnInit(): void {

    this.notifService.assignAll(this.user.notifications);

    this.notifService.notifications$.subscribe((notifs) => {
      this.unreadNotifications = notifs.reverse().filter(item => item.seen_at === null)
      this.readNotifications = notifs.reverse().filter(item => item.seen_at !== null)
    })

    this.notifService.fetching$.subscribe((value) => this.fetching = value)

  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: PointerEvent): void {

    const dropdown = document.getElementById('notification-dropdown');

    var t = event.target as any

    if (dropdown && t.parentNode?.className?.toString().indexOf('icon-container') == -1 && this.isDropdownOpen || t.parentNode?.className === undefined) {
      this.closeDropdown();
    }

  }

  viewNotification(notification: HostNotification) {
    this.showNotification = true
    this.selectedNotification = notification
    this.closeDropdown()
    if (notification.seen_at === null)
      this.applyReadToNotification()
  }

  applyReadToNotification() {
    const now = new Date()
    // mark the notification as read (Locally)
    this.selectedNotification.seen_at = now.toISOString()
    this.notifService.updateNotifications(this.selectedNotification)
    // local storage
    this.applyReadToLocalStorage(this.selectedNotification.notificationid);
    // mark the notification read (database)
    this.notifService.readNotification(this.selectedNotification)
      .subscribe((response: HostNotification) => {
        this.notifService.updateNotifications(response)
      })
  }

  applyReadToLocalStorage(notificationId: string){

    const userDetailStr = localStorage.getItem('user_detail');
    if (userDetailStr) {
      const userDetail = JSON.parse(userDetailStr);
      const notificationIndex = userDetail.notifications.findIndex(
        (n: any) => n.notificationid === notificationId
      );

      if (notificationIndex > -1) {
        const n =  userDetail.notifications[notificationIndex];
        n.seen_at = new Date().toISOString();
        userDetail.notifications[notificationIndex] = n;

        const updatedUserDetailStr = JSON.stringify(userDetail);

        localStorage.setItem('user_detail', updatedUserDetailStr);
      }
    }

  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onDropdownClick(event: Event): void {
    event.stopPropagation();
  }

  closeDropdown(): void {
    this.isDropdownOpen = false;
  }

  readableDate(date: string): String {
    return this.notifService.readableDate(date)
  }
}
