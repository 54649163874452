import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ShiftService {

	rootUri = environment.root_uri;

	constructor(private http: HttpClient) { }

	getGameShifts(gameid: string): Observable<Shift[]> {
		return this.http.get<Shift[]>(`${this.rootUri}/api/shift/list/by-game/${gameid}`)
	}

	getMyShift(gameid: string) {
		return this.http.get(`${this.rootUri}/api/shift/by-game/${gameid}/my-shift`)
	}

	editGameShift(shiftid: string, data: Shift | ShiftStats | ShiftTimes) {
		return this.http.post(`${this.rootUri}/api/shift/${shiftid}/edit`, data);
	}

	deleteWorkBlock(shiftid: string, work_code: string) {
		return this.http.post(`${this.rootUri}/api/shift/${shiftid}/work-block/${work_code}/delete`, {});
	}

	editWorkBlock(shiftid: string, data: WorkTime) {
		return this.http.post(`${this.rootUri}/api/shift/${shiftid}/work-block/${data.work_code}/edit`, data);
	}

	newWorkBlock(shiftid: string, data: WorkTime) {
		return this.http.post(`${this.rootUri}/api/shift/${shiftid}/work-block/new`, data);
	}

	reopenShift(shiftid: string) {
		return this.http.post(`${this.rootUri}/api/shift/${shiftid}/restart`, {});
	}

	editRecap(shiftid: string, recap: string) {
		let postBody: any = { recap };
		return this.http.post(`${this.rootUri}/api/shift/${shiftid}/edit`, postBody);
	}

	submitShift(shiftid: string) {
		return this.http.post(`${this.rootUri}/api/shift/${shiftid}/submit`, {});
	}

	endShift(shiftid: string, end: string) {
		return this.http.post(`${this.rootUri}/api/shift/${shiftid}/end`, { end });
	}

	deleteShift(id: string) {
		return this.http.delete(`${this.rootUri}/api/shift/${id}/delete`);
	}
}
