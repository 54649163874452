import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './auth/token.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { HeaderComponent } from './header/header.component';
import { GameListComponent } from './game-list/game-list.component';
import { GameDetailComponent } from './game-detail/game-detail.component';
import { LiveGameComponent } from './live-game/live-game.component';
import { RoundComponent } from './round/round.component';
import { QuestionComponent } from './question/question.component';
import { TeamDetailComponent } from './team-detail/team-detail.component';
import { UniqueAnswersPipe } from './unique-answers.pipe';
import { ScoreQuestionComponent } from './score-question/score-question.component';
import { CorrectAnswersPipe } from './correct-answers.pipe';
import { RoundScoreComponent } from './round-score/round-score.component';
import { CorrectTeamAnswersByQuestionPipe } from './correct-team-answers-by-question.pipe';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { ProfileComponent } from './profile/profile.component';
import { AdminViewComponent } from './admin-view/admin-view.component';
import { RoundSummaryComponent } from './round-summary/round-summary.component';
import { RoundTeamSummaryComponent } from './round-team-summary/round-team-summary.component';
import { TeamsForRoundSummaryPipe } from './teams-for-round-summary.pipe';
import { GameStubComponent } from './game-stub/game-stub.component';
import { GameSearchPipe } from './game-search.pipe';
import { RecapsComponent } from './recaps/recaps.component';
import { RecapStubComponent } from './recap-stub/recap-stub.component';
import { ReferralComponent } from './referral/referral.component';
import { TechSupportComponent } from './tech-support/tech-support.component';
import { ScoreAnswerComponent } from './score-answer/score-answer.component';
import { AdminGameControlsComponent } from './admin-game-controls/admin-game-controls.component';
import { AdminHostControlsComponent } from './admin-host-controls/admin-host-controls.component';
import { MiscLinksComponent } from './misc-links/misc-links.component';
import { GameRecapComponent } from './game-recap/game-recap.component';
import { GameRecapStatsComponent } from './game-recap-stats/game-recap-stats.component';
import { GameRecapShiftComponent } from './game-recap-shift/game-recap-shift.component';
import { GameRecapPublicComponent } from './game-recap-public/game-recap-public.component';
import { DateDisplayEditComponent } from './date-display-edit/date-display-edit.component';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';
import { TeamManagementComponent } from './team-management/team-management.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ImageListComponent } from './image-list/image-list.component';
import { BasicModalComponent } from './basic-modal/basic-modal.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { NotificationBellComponent } from './notification-bell/notification-bell.component';
import { SkeletonLoaderComponent } from './skeleton-loader/skeleton-loader.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import './hbspt.ts';


@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    HeaderComponent,
    GameListComponent,
    GameDetailComponent,
    LiveGameComponent,
    RoundComponent,
    QuestionComponent,
    TeamDetailComponent,
    UniqueAnswersPipe,
    ScoreQuestionComponent,
    CorrectAnswersPipe,
    RoundScoreComponent,
    CorrectTeamAnswersByQuestionPipe,
    MainMenuComponent,
    ProfileComponent,
    AdminViewComponent,
    RoundSummaryComponent,
    RoundTeamSummaryComponent,
    TeamsForRoundSummaryPipe,
    GameStubComponent,
    GameSearchPipe,
    RecapsComponent,
    RecapStubComponent,
    ReferralComponent,
    TechSupportComponent,
    ScoreAnswerComponent,
    AdminGameControlsComponent,
    AdminHostControlsComponent,
    MiscLinksComponent,
    GameRecapComponent,
    GameRecapStatsComponent,
    GameRecapShiftComponent,
    GameRecapPublicComponent,
    DateDisplayEditComponent,
    ImageCarouselComponent,
    TeamManagementComponent,
    ImageListComponent,
    BasicModalComponent,
    SpinnerComponent,
    SafeHtmlPipe,
    NotificationBellComponent,
    SkeletonLoaderComponent,
    ForgotPasswordComponent,
    NewPasswordComponent
  ],
  imports: [
    BrowserModule,
    EditorModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
