import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Game } from '../classes/Game';
import { User } from '../classes/User';
import { GameService } from '../services/game.service';
import { LocalTimeService } from '../services/local-time.service';
import { RecapService } from '../services/recap.service';
import { ShiftService } from '../services/shift.service';
import { finalize } from 'rxjs/operators';

@Component({
	selector: 'app-admin-host-controls',
	templateUrl: './admin-host-controls.component.html',
	styleUrls: ['./admin-host-controls.component.css']
})
export class AdminHostControlsComponent implements OnInit {

	@Input() user: User;
	@Input() shift: Shift;
	@Input() game: Game;
	@Input() index: number;
	@Input() expanded: number;
	@Input() controlVis: boolean;

	@Output() expand: EventEmitter<number> = new EventEmitter();
	@Output() error: EventEmitter<string> = new EventEmitter();
	@Output() reloadGame: EventEmitter<boolean> = new EventEmitter();

	resetWarn: boolean;
	endShiftWarn: boolean;
	loading: boolean;

	editWorkTimes: { start: LocalTime, end: LocalTime }

	constructor(
		private timeServe: LocalTimeService,
		private recapServe: RecapService,
		private shiftServe: ShiftService) { }

	ngOnInit(): void { }

	deleteShift() {
		if (!this.shift) {
			this.error.emit("Please select a shift to delete")
			return
		}
		this.shift.warn_delete = false
		this.shiftServe.deleteShift(this.shift.shiftid)
			.pipe(finalize(() => {
				this.shift = null
			}))
			.subscribe(response => {
				this.reloadGame.emit(true);
			}, err => this.error.emit(err.error.detail)
			)

	}

	startExpand() {
		this.expand.emit(this.index);
	}

	timeLocal(time: string) {
		let d = new Date(time);
		return d.toLocaleString();
	}

	startTimeEdit() {
		let start = this.timeServe.dateStringToLocalTime(this.shift.work[0].start_work);
		let end = this.timeServe.dateStringToLocalTime(this.shift.work[0].end_work);

		this.editWorkTimes = {
			start: typeof start === 'string' ? { date: '', time: '', offset: '' } : start,
			end: typeof end === 'string' ? { date: '', time: '', offset: '' } : end
		}
	}


	saveWorkTimes() {
		this.error.emit("");
		let start = this.timeServe.localTimeToIso8601(this.editWorkTimes.start);
		let end = this.timeServe.localTimeToIso8601(this.editWorkTimes.end);
		if (!start) {
			this.error.emit("Shift must include a start time!");
			return;
		}
		if (!end && this.game.status === 'ended') {
			this.error.emit("Cannot leave end time blank: Game is ended!")
			return;
		}
		if (end && start > end) {
			this.error.emit("Shift cannot end before it begins!");
			return;
		}
		this.loading = true;
		this.error.emit("");



		let worktime = {
			start_work: start,
			end_work: end,
			description: this.shift.work[0].description,
			work_code: this.shift.work[0].work_code
		}

		this.shiftServe.editWorkBlock(this.shift.shiftid, worktime)
			.pipe(finalize(() => this.loading = false))
			.subscribe(res => {
				this.shift.work[0].start_work = start;
				this.shift.work[0].end_work = end;
				this.editWorkTimes = undefined;
			}, err => this.error.emit(err.error.detail))
	}

	reopenShift() {
		// check that game is still live (otherwise error)
		if (this.game.status !== "live") {
			this.error.emit('Cannot reopen shift: Game no longer live.');
			return;
		}
		this.loading = true;
		this.error.emit("");
		// reopen shift
		this.shiftServe.reopenShift(this.shift.shiftid)
			.pipe(finalize(() => this.loading = false))
			.subscribe(
				res => {
					this.shift.work[0].end_work = "";
					this.shift.status = "live";
					this.resetWarn = false;
					this.reloadGame.emit(true);
				},
				err => this.error.emit(err.error.detail))
	}

	endShift() {


		if (this.shift.status !== "live") {
			this.error.emit('Cannot end shift as it is already ended.');
			return;
		}

		this.loading = true;
		this.error.emit("");

		const d = new Date();
		const end = d.toISOString();

		const d_localTime = this.timeServe.dateStringToLocalTime(end);
		let end_iso = this.timeServe.localTimeToIso8601(d_localTime)

		this.shiftServe.endShift(this.shift.shiftid, end_iso)
			.pipe(finalize(() => this.loading = false))
			.subscribe(res => {
				this.shift.work[0].end_work = end_iso;
				this.shift.status = "draft";
				this.editWorkTimes = undefined;
				this.endShiftWarn = false
			}, err => this.error.emit(err.error.detail))
	}

}
