import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DateDisplayEditComponent } from '../date-display-edit/date-display-edit.component';

@Component({
  selector: 'app-game-recap-shift',
  templateUrl: './game-recap-shift.component.html',
  styleUrls: ['./game-recap-shift.component.css']
})
export class GameRecapShiftComponent implements OnInit {

	@Input() shift: Shift;
	@Input() mode: '' | 'verify' | 'edit';

	@Output() endingShift: EventEmitter<boolean> = new EventEmitter();
	@Output() cancelEdit: EventEmitter<boolean> = new EventEmitter();
	@Output() doneEditing: EventEmitter<WorkTime[]> = new EventEmitter();

	times: ShiftTimes;

	verifyMsgs: string[] = [];

	expectedTimeMins: number;
	totalTimeMins: number = 0;
	totalTimeStr: string = "00:00";

	confirming: boolean;

	confirmedTime: boolean;

	constructor() { }

	ngOnInit(): void {
		this.resetTimes();
		if (this.mode === 'verify') {
			this.verify();
		}
	}

	verify() {
		this.verifyMsgs = [];
		for (let w of this.times.work) {
			if (!w.markDelete) {
				w.startError = w.start_work ? false : true;
				w.endError = w.end_work ? false : true;
				w.descError = w.description ? false : true;
				if (w.startError || w.endError || w.descError) {
					this.verifyMsgs.push("Missing a start time, end time, or description");
				}
				if (this.minsBetween(w.start_work, w.end_work) < 0) {
					w.endError = true;
					this.verifyMsgs.push("Start time must come before end time");
				}
			}
		}
		if (!this.verifyMsgs.length) {
			if (!this.confirmedTime) {
				this.verifyMsgs.push("Your time worked is a bit higher than expected. Please confirm.")
			}
		}
		return this.verifyMsgs;
	}

	resetTimes() {
		let newWork = [];
		for (let i = 0; i < this.shift.work.length; i++) {
			let ow = this.shift.work[i];
			newWork.push({
				start_work: ow.start_work,
				end_work: ow.end_work,
				description: ow.description,
				work_code: ow.work_code
			})
		}
		this.times = {
			expected_start: this.shift.expected_start,
			expected_end: this.shift.expected_end,
			expected_mins: this.minsBetween(
				this.shift.expected_start,
				this.shift.expected_end
			),
			work: newWork
		}
		this.setTotalTime();
	}

	confirmTimes() {
		this.confirmedTime = true;
		this.verifyMsgs = [];
	}

	formatTimeBetween(start: string, end: string) {
		if (!start || !end) return "---";
		return this.formatMins(this.minsBetween(start, end));
	}

	minsBetween(start: string, end: string) {
		if (!start || !end) return 0;
		let s = new Date(start).valueOf();
		let e = new Date(end).valueOf();
		return Math.round((e - s) / 60000);
	}

	formatMins(mins: number, format: 'HH:MM' | 'H' | 'M' = 'HH:MM') {
		let hours = Math.floor(mins / 60);
		if (format === 'H') {
			return hours.toString();
		}
		let hstr = `${hours < 10 ? '0' : ''}${hours}`;
		mins = mins - Math.round(hours * 60);
		if (format === 'M') {
			return mins.toString();
		}
		return `${hstr}:${mins < 10 ? '0' : ''}${mins}`;
	}

	setTotalTime(as_num: boolean = false) {
		let total = 0;
		for (let w of this.times.work) {
			if (w.startError || w.endError) {
				this.totalTimeStr = "--:--";
				this.totalTimeMins = 0;
				return;
			}
			if (!w.markDelete) {
				total += this.minsBetween(w.start_work, w.end_work);
			}
		}
		this.totalTimeStr = this.formatMins(total);
		this.totalTimeMins = total;
		this.expectedTimeMins = this.minsBetween(this.shift.expected_start, this.shift.expected_end);
		this.confirmedTime = this.totalTimeMins <= this.expectedTimeMins ? true : false;
		this.verifyMsgs = [];
	}

	addWorkBlock() {
		this.times.work.push({
			start_work: null,
			end_work: null,
			description: "",
			work_code: null
		})
	}

	removeWorkBlock(idx: number) {
		this.times.work[idx].markDelete = true;
		this.setTotalTime();
	}

	newTime(idx: number, prop: 'start_work' | 'end_work', value: string | null) {
		let target = this.times.work[idx];
		target[prop] = value;
		let errProp = prop === 'start_work' ? 'startError' : 'endError';
		target[errProp] = value ? false : true;
		this.setTotalTime();
	}

	cancel() {
		this.cancelEdit.emit(true);
		this.resetTimes();
	}

	save() {
		if (this.mode === 'verify') {
			this.verify();
			if (this.verifyMsgs.length) return;
		}
		this.doneEditing.emit(this.times.work);
	}

	endShift() {
		this.endingShift.emit(true);
	}

}
