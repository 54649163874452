<div *ngIf="!editing; else editForm" class="game-recap-public--display-container">
	<div class="game-recap-public--images">
		<app-image-carousel *ngIf="!imageLoading; else loadMsg" [images]="approved"></app-image-carousel>
	</div>
	<div class="game-recap-public--blog-score">
		<div *ngIf="shift.recap" class="game-recap-public--blog-viewer" [innerHTML]="shift.recap | safeHtml">
			<p>No recap written yet...</p>
		</div>
		<div *ngIf="!shift.recap" class="game-recap-public--blog-viewer">
			<p>No recap written yet...</p>
		</div>
		<div *ngIf="!game.use_external_app">
			<div class="game-recap-public--header">
				<div class="game-recap-public--rank">Rank</div>
				<div class="game-recap-public--team-id">Team ID</div>
				<div class="game-recap-public--team-name">Team Name</div>
				<div class="game-recap-public--score">Score</div>
			</div>
			<div class="game-recap-public--row" *ngFor="let t of tDisplay; let i = index">
				<div class="game-recap-public--rank">{{t.rank}}</div>
				<div class="game-recap-public--team-id">{{t.kt_team_id}}</div>
				<div class="game-recap-public--team-name">{{t.team_name}}</div>
				<div class="game-recap-public--score">{{t.score}}</div>
			</div>
		</div>
	</div>
</div>

<ng-template #editForm>
	<div *ngIf="!loading; else loadMsg">
		<div class="game-recap-public--content">
			<div class="game-recap-public--image-edit">
				<app-image-list 
					[game]="game"
					[user]="user"
					[images]="images"
					(newImages)="addImages($event)"
					(setBusy)="busyUpload = $event"></app-image-list>
			</div>
			<div class="game-recap-public--blog-score">
				<div class="game-recap-public--blog">
					<editor 
						[(ngModel)]="recap.recap"
						apiKey="dwq4ypdrl015pq2mos8palrnjg0f2dujrq31o5fzwyvy0pml"
						[init]="{
							height: 300,
							menubar: false,
							plugins: 'link lists',
							toolbar: 'undo redo | bold italic underline blockquote strikethrough | alignleft aligncenter alignright | bullist numlist | link',
							link_context_toolbar: true
						}"></editor>
				</div>
				<div *ngIf="!game.use_external_app" class="game-recap-public--teams">
					<app-team-management *ngIf="game.status === 'ended'; else noScoreEdit" [game]="game" [noWS]="true"></app-team-management>
				</div>
			</div>
		</div>
		<div *ngIf="!busyUpload" class="hrz-between">
			<button class="recap-ctrl-red" (click)="cancel()">Cancel</button>
			<button class="recap-ctrl-green" (click)="saveRecap()">Save</button>
		</div>
	</div>
</ng-template>

<ng-template #loadMsg>
	<div>
		<h3 class="center-text">Updating your Recap. One moment...</h3>
	</div>
</ng-template>

<ng-template #imgLoadMsg>
	<div>
		<h3 class="center-text">Loading Images...</h3>
	</div>
</ng-template>

<ng-template #noScoreEdit>
	<div>
		<h3 class="center-text">Final scores cannot be edited while game is still live.</h3>
	</div>
</ng-template>