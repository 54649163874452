<span *ngIf="state === 'view'; else editDate">{{dateFormat(origDate)}}</span>

<ng-template #editDate>
	<div *ngIf="!disable; else disabled" class="date-display-edit--row">
		<input 
			type="date"
			class="pill-box"
			[(ngModel)]="date" 
			(focusout)="updateDateTime()" 
			[ngClass]="error ? 'input-error' : ''">
		<input 
			type="time"
			class="pill-box"
			[(ngModel)]="time" 
			(focusout)="updateDateTime()" 
			[ngClass]="error ? 'input-error' : ''">
	</div>
</ng-template>

<ng-template #disabled>
	<div class="date-display-edit--row">
		<input 
			type="date"
			class="pill-box"
			disabled>
		<input 
			type="time"
			class="pill-box"
			disabled>
	</div>
</ng-template>