import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Game } from '../classes/Game';
import { Team } from '../classes/Team';
import { Round } from '../classes/QSet';
import { CorrectAnswersPipe } from '../correct-answers.pipe';
import { GameService } from '../services/game.service';

@Component({
  selector: 'app-round-score',
  templateUrl: './round-score.component.html',
  styleUrls: ['./round-score.component.css']
})
export class RoundScoreComponent implements OnInit {

	@Input() game: Game;
	@Input() team: Team;
	@Input() round: Round;
	@Input() breakdown: any[];

	@Output() reset: EventEmitter<any> = new EventEmitter();

	doubled: boolean;
	detail: boolean;
	total: number;

	editScore: boolean;
	newScore: number;

	answerPipe = new CorrectAnswersPipe();

	constructor(private gameServe: GameService, private changeDetector: ChangeDetectorRef) { }

	ngOnInit(): void {
		if (this.scoreIsSet()) {
			this.newScore = this.manualScore()
		}
	}

	totalScore() {
		if (!this.breakdown) return 0;
		let total = 0;
		for (let q of this.breakdown) {
			for (let a of q) {
				if (this.game.teamDoubledRound(this.team.team_code, this.round.round_id) && !a.correct) return 0;
				else total += a.score;
			}
		}
		return (this.game.teamDoubledRound(this.team.team_code, this.round.round_id)) ? total * 2 : total;
	}

	resetAnswers() {
		this.gameServe.resetRoundForTeam(this.game.gameid, this.round.round_id, this.team.team_code)
			.subscribe((res:any) => {
				this.breakdown = null;
				this.detail = false;
				//this.changeDetector.detectChanges();
				//this.reset.emit(true);
			}, err => {})
	}

	scoreIsSet() {
		let rnd = this.game.answered['r_'+this.round.round_id];
		if (rnd) {
			let tm = rnd['t_'+this.team.team_code];
			if (tm) {
				if (tm.score !== null && tm.score !== undefined) {
					return true;
				}
			}
		}
		return false;
	}

	manualScore() {
		let rnd = this.game.answered['r_'+this.round.round_id];
		if (rnd) {
			let tm = rnd['t_'+this.team.team_code];
			if (tm) {
				return tm.score;
			}
		}
		return null;
	}

	startScoreEdit() {
		if (this.scoreIsSet()) {
			this.newScore = this.manualScore()
		}
		else this.newScore = this.totalScore();
		this.editScore = true;
	}

	setScore() {
		if (!this.team.manual && !this.scoreIsSet() && this.newScore === this.totalScore()) return;
		if (this.newScore === this.manualScore()) return;
		this.editScore = false;
		this.gameServe.scoreTeamRound(this.game.gameid, this.round.round_id, this.team.team_code, this.newScore)
			.subscribe(
				res => {},
				err => {
					console.log(err);
				})
	}

}
