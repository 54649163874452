<div class="routed-container">
	<div class="routed-content">
		<div class="routed-bumper">
			<h1 class="center-text sm-my">Admin View</h1>
			<div class="md-mb date-header">
				<h2 class="no-my vert-center center-text">
					<span>All Games Between</span>
				</h2>
				<div class="date-ctl center-text hrz-center">
					<div class="vert-center">
						<div class="from-to">
							<input #fromDate class="md-ml" type="date" [value]="parseDateUTC(from)">
							<input #toDate class="md-ml" type="date" [value]="parseDateUTC(to)">
						</div>
					</div>
					<button (click)="updateDates(fromDate.value, toDate.value)" class="basic-btn-red std-ml">Update</button>
				</div>
			</div>
			<div class="search-sort-header">
				<div class="md-mb hrz-between search-by">
					<div class="search-sort-label vert-center">
						<span>Search:</span>
					</div>
					<div class="search-sort-ctl">
						<input class="w-100" type="text" [(ngModel)]="search" placeholder="venue, event, or host...">
					</div>
				</div>
				<div class="hrz-between md-mb sort-by">
					<div class="search-sort-label vert-center">
						<span>Sort By:</span>
					</div>
					<div class="search-sort-ctl hrz-between">
						<select [value]="sortBy" (change)="sortGames($event.target.value)">
							<option value="venue">Venue</option>
							<option value="hosts">Quizmaster</option>
							<option value="date">Date</option>
							<option value="status">Status</option>
						</select>
						<div (click)="flipSort()" class="asc-dsc hrz-center clickable">
							<div class="vert-center">
								<i *ngIf="asc" class="material-icons">arrow_upward</i>
								<i *ngIf="!asc" class="material-icons">arrow_downward</i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<h3 *ngIf="loading" class="center-text">Loading Games...</h3>
			<div *ngIf="!loading">
				<app-game-stub *ngFor="let g of games | gameSearch:search" [game]="g" [admin]="true"></app-game-stub>
			</div>
		</div>
	</div>
</div>

