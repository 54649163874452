import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-date-display-edit',
	templateUrl: './date-display-edit.component.html',
	styleUrls: ['./date-display-edit.component.css']
})
export class DateDisplayEditComponent implements OnInit {

	@Input() origDate: string;
	@Input() state: 'view' | 'edit';
	@Input() timeOnly: boolean;
	@Input() error: boolean;
	@Input() disable: boolean;

	@Output() edited: EventEmitter<string | null> = new EventEmitter();

	date: string;
	time: string;

	monthDays = [
		null,
		31,
		28,
		31,
		30,
		31,
		30,
		31,
		31,
		30,
		31,
		30,
		31
	];

	leapYears = [1996, 2000, 2024, 2028, 2032, 2036, 2040, 2044, 2048];

	constructor() { }

	ngOnInit(): void {
		this.setDateTime(this.origDate);
	}

	setDateTime(dtStr: string | Date) {
		if (dtStr) {
			let dt = new Date(dtStr);
			let year = dt.toLocaleString('en-US', { year: 'numeric' });
			let month = dt.toLocaleString('en-US', { month: '2-digit' });
			let day = dt.toLocaleString('en-US', { day: '2-digit' });
			this.date = `${year}-${month}-${day}`;
			let hour = dt.toLocaleString('en-US', { hour: '2-digit', hour12: false });
			let minute = dt.toLocaleString('en-US', { minute: '2-digit' });
			minute = minute.length < 2 ? `0${minute}` : minute;
			this.time = `${hour}:${minute}`;
		}
	}

	dateFormat(date: string) {
		if (!date) return "---";
		let dt = new Date(date);
		// manually extract date components
		let year = dt.getFullYear();
		let month = (dt.getMonth() + 1).toString().padStart(2, '0');
		let day = dt.getDate().toString().padStart(2, '0');
		let hours = dt.getHours();
		let ampm = hours >= 12 ? 'PM' : 'AM';
		hours = hours % 12;
		hours = hours ? hours : 12;
		let minutes = dt.getMinutes().toString().padStart(2, '0');

		let formattedDate = '';

		if (!this.timeOnly) {
			formattedDate = `${year}-${month}-${day}`;
		}

		if (!this.timeOnly || !formattedDate) {
			formattedDate += ' ';
		}

		formattedDate += `${hours}:${minutes} ${ampm}`;

		return formattedDate.trim();
	}

	updateDateTime() {
		if (!this.date || !this.time) {
			this.edited.emit(null);
		}
		else {
			this.edited.emit(this.formatOutputString());
		}
	}

	formatOutputString() {
		let offset = new Date(this.date).getTimezoneOffset();
		let dPrts = this.date.split('-');
		let dValue = {
			year: parseInt(dPrts[0]),
			month: parseInt(dPrts[1]),
			day: parseInt(dPrts[2])
		}
		let tPrts = this.time.split(':');
		let tValue = (parseInt(tPrts[0]) * 60) + parseInt(tPrts[1]);
		tValue += offset;
		if (tValue < 0) {
			tValue = 1440 + tValue;
			dValue.day--;
			if (dValue.day < 1) {
				dValue.month--;
				if (dValue.month < 1) {
					dValue.month = 12;
					dValue.year--;
				}
				dValue.day = this.monthDays[dValue.month];
				if (dValue.month = 2) {
					for (let y of this.leapYears) {
						if (y === dValue.year) dValue.day++;
						break;
					}
				}
			}
		}
		if (tValue >= 1440) {
			tValue = tValue - 1440;
			dValue.day++;
			let leap = 0;
			if (dValue.month === 2) {
				for (let y of this.leapYears) {
					if (y === dValue.year) leap = 1;
					break;
				}
			}
			if (dValue.day > this.monthDays[dValue.month] + leap) {
				dValue.month++;
				if (dValue.month > 12) {
					dValue.month = 1;
					dValue.year++;
				}
				dValue.day = 1;
			}
		}
		let dtStr = `${dValue.year}-${dValue.month < 10 ? '0' : ''}`
		dtStr = `${dtStr}${dValue.month}-${dValue.day < 10 ? '0' : ''}`;
		dtStr = `${dtStr}${dValue.day}`
		let hrs = Math.floor(tValue / 60);
		let mins = tValue - (hrs * 60);
		let tStr = `${hrs < 10 ? '0' : ''}${hrs}:${mins < 10 ? '0' : ''}${mins}`
		return `${dtStr}T${tStr}:00+00:00`;
	}

}
