<div class="routed-container">
	<div *ngIf="!loading" class="routed-content">
		<div class="std-p">
			<h2 class="center-text">Client Referral Program</h2>
			<p>
				<strong>How does it work?</strong>
				<br>
				<span>Pass out your personalized referral card to potential new clients. If they book an event and mention your name, you get paid! ($100 for a weekly event, $25 for a private event)</span>
			</p>
			<div class="hrz-center">
				<button class="basic-btn-red" (click)="downloadReferralCard()">Download Your Referral Card</button>
			</div>
			<p class="no-mb">
				<strong>Who should I give these to?</strong>
			</p>
			<ul class="no-mt">
				<li>Anyone who inquires about a private event</li>
				<li>Players at your game who mention they work at a bar or restaurant</li>
				<li>Your favorite bar that doesn't currently have King Trivia</li>
				<li>Any bar you step into that you think might be good for trivia</li>
			</ul>
			<p class="no-mb">
				<strong>The fine print:</strong>
			</p>
			<ol class="no-mt">
				<li>The prospective client must fill out the form linked in the QR code on your referral card.</li>
				<li>If a prospective client fills out the form more than once, with a different Quizmaster's name each time, we will only pay out the quizmaster mentioned on the first form submission.</li>
				<li>Referral fees will only be paid out for the client's first event with King Trivia. If a client books a weekly event, we will payout $100 total, not $100 per week.</li>
				<li>Client must book King Trivia within 6 months of filling out the form.</li>
				<li>For weekly events, the referral fee is paid out once the client has run 6 weekly events. For private events, the referral fee will be paid out 7 days after the event occurs.</li>
				<li>This program will remain in effect until further notice and can be cancelled or changed at any time.</li>
			</ol>
			<p>
				<strong>For questions about the referral program, contact Ashley Menzel at 818-808-0008 or amenzel@kingtrivia.com</strong>
			</p>
		</div>
	</div>

	<h2 *ngIf="loading">Fetching Referral Information...</h2>
</div>
