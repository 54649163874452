import { Pipe, PipeTransform } from '@angular/core';
import { Game } from './classes/Game';
import { Team } from './classes/Team';
import { Round } from './classes/QSet';

@Pipe({
  name: 'teamsForRoundSummary'
})

export class TeamsForRoundSummaryPipe implements PipeTransform {

  transform(teams: Team[], game: Game, round : Round, type: string, asc: boolean, resetter: boolean): TeamBundle[] {
  	// console.log("recreating round summary...",);
  	// bundle teams with submission status and scores
  	let bundles = teams.map((team) => {
  		let out: TeamBundle = {
  			team: team,
  			teamName: team.team_name,
  			submitted: game.teamSubmittedRound(round.round_id, team.team_code),
  			roundScore: game.teamScoreForRound(team.team_code, round.round_id),
  			totalScore: game.teamScore(team.team_code),
  			doubled: game.teamDoubledRound(team.team_code, round.round_id),
  			breakdown: game.answerPipe.transform(game, round.round_id, team.team_code, true),
  			overrideScore: game.teamOverrideScoreForRound(team.team_code, round.round_id)
  		};
  		return out;
  	});

  	// sort teamBundles based on criteria
  	bundles.sort((a, b) => {
  		let aVal = a[type];
  		let bVal = b[type];
  		if (type == "roundScore") {
  			aVal = (a.overrideScore !== null && a.overrideScore !== undefined) ? a.overrideScore : aVal;
  			bVal = (b.overrideScore !== null && b.overrideScore !== undefined) ? b.overrideScore : bVal;
  		}
  		if (aVal > bVal) return (asc) ? 1 : -1;
  		else if (aVal < bVal) return (asc) ? -1 : 1;
  		else return 0;
  	});
  	
  	return bundles;

  }

}

type TeamBundle = {
	team: Team;
	teamName: string;
	submitted: boolean;
	roundScore: number;
	totalScore: number;
	doubled: boolean;
	breakdown: any[];
	overrideScore?: number;
}