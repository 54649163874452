<h3 class="no-mb">{{question.question_text}}</h3>
<ul class="no-mt">
	<li *ngFor="let a of question.answers">{{a.original}}</li>
</ul>
<p class="response-header">
	<span>Responses: </span>
	<span [ngClass]="{'sim-link': viewBy === 'team'}" (click)="viewBy = 'answer'">By Answer</span>
	<span> / </span>
	<span [ngClass]="{'sim-link': viewBy === 'answer'}" (click)="viewBy = 'team'">By Team</span>
</p>
<div *ngIf="viewBy === 'answer'">
	<app-score-answer 
		*ngFor="let uv of game | uniqueAnswers:question:round.round_id:q_idx:game.resetter" 
		[uv]="uv" 
		[question]="question" 
		[round]="round" 
		[game]="game" 
		(reset)="processReset()"></app-score-answer>
</div>
<div *ngIf="viewBy === 'team'">
	<div *ngFor="let t of game.getQuestionResponses(round.round_id, q_idx)" class="md-p sm-mb team-answer-box">
		<h3 class="no-mt md-mb">{{t.team_name}}</h3>
		<app-score-answer
			*ngFor="let a of t.answers | correctTeamAnswersByQuestion:question"
			[uv]="a"
			[question]="question"
			[round]="round"
			[game]="game"
			[viewOnly]="true"
			(reset)="processReset()"></app-score-answer>
	</div>
</div>
	