import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Game } from '../classes/Game';
import { Round, Question } from '../classes/QSet';
import { GameService } from '../services/game.service';

@Component({
  selector: 'app-score-question',
  templateUrl: './score-question.component.html',
  styleUrls: ['./score-question.component.css']
})
export class ScoreQuestionComponent implements OnInit {

	@Input() game: Game;
	@Input() round: Round;
	@Input() question: Question;
	@Input() q_idx: number;

	@Output() addVariant: EventEmitter<{answer: string, index: number}> = new EventEmitter()
	@Output() addIncorrect: EventEmitter<string> = new EventEmitter()

	reset: boolean;

	viewBy = "answer";

	constructor(private cdRef: ChangeDetectorRef) { }

	ngOnInit(): void {
		
	}

	passVariant(variant: {answer: string, index: number}) {
		this.addVariant.emit(variant);
	}

	passIncorrect(incorrect: string) {
		this.addIncorrect.emit(incorrect);
	}

	processReset() {
		if (!this.question.isUpdating()) {
			console.log("applying changes from question level!");
			this.question.processQueue();
			this.cdRef.detectChanges();
		}
		console.log("Question is still busy... declining change detection");
	}

}
