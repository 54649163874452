<div *ngIf="user && user.admin" class="admin-ctrls">
	<p *ngIf="err" class="error-message">{{err}}</p>
	<p class="no-m">Assigned Host(s):</p>
	<ul *ngIf="!loading; else loadMsg" class="no-m no-p host-list" (mouseleave)="controlVis = null">
		<li *ngFor="let s of shifts; let i = index" class="md-p host-item" (mouseenter)="controlVis = i">
			<app-admin-host-controls [user]="user" [shift]="s" [expanded]="expanded" [controlVis]="controlVis"
				[index]="i" [game]="game" (expand)="expandHost($event)" (error)="err = $event"
				(reloadGame)="passReload()"></app-admin-host-controls>
		</li>
	</ul>
</div>

<ng-template #loadMsg>
	<h3>Fetching Hosts...</h3>
</ng-template>