import { QSet, Round, Question } from './QSet';
import { Team } from './Team';
import { CorrectAnswersPipe } from '../correct-answers.pipe';

export type GameStub = {
	kt_id: number;
	gameid?: string;
	title: string;
	date: string;
	display_date?: string;
	venue: string;
	status: string;
  venue_tz: string;
	slot_id?: string;
	hosts?: [{ email: string, name: string, employee_id: string }];
}

export type FinalScore = {
	team_id: string,
	team_name_alternate: string,
	score: number,
	team_code?: string,
	pending_change?: boolean
}

export class Game {
	kt_id: number;
	gameid: string;
	event_id: string;
	clientid: string;
	title: string;
	date: string;
	utc_date: string;
	niceDate: string;
	ihh: boolean;
	private_event: boolean;
	use_external_app: boolean;
	started: string = "";
	ended: string = "";
	code: string = "";
	venue: string;
	venueid: string;
	venue_address = ""
	venue_city = ""
	venue_state = ""
	venue_zip = ""
	venue_phone = ""
	status: string;
	question_set: QSet;
	teams: Team[];
	round_index: number = null;
	last_round: number = null;
	submissions_open: boolean;
	asked: any;
	answered: any;
	needsReview: any[] = [];
	answerPipe = new CorrectAnswersPipe();
	resetter: boolean;
	empty_tables: number;
	total_players: number;
	total_teams: number;
	shift_status: string;
	first_action: string;
	last_action: string;
	scores: FinalScore[];
	hosts: []
	hosts_shift_status: string[]
	server_date: string

	constructor(gm) {
		this.kt_id = gm.kt_id;
		this.gameid = gm.gameid;
		this.title = gm.title;
		this.private_event = gm.private_event ? true : false;
		this.use_external_app = gm.use_external_app ? true : false;
		this.event_id = gm.event_id;
		let d = new Date(gm.date);
		this.date = d.toLocaleString();
		this.utc_date = gm.date;
		if (gm.started) {
			let s = new Date(gm.started);
			this.started = s.toLocaleString();
		}
		if (gm.ended) {
			let e = new Date(gm.ended)
			this.ended = e.toLocaleString();
		}
		if (gm.code) {
			this.code = (typeof gm.code === "number")
				? gm.code.toString()
				: gm.code;
		}
		this.venue = gm.venue;
		this.venue_address = gm.venue_address;
		this.venue_city = gm.venue_city;
		this.venue_state = gm.venue_state;
		this.venue_zip = gm.venue_zip;
		this.venue_phone = gm.venue_phone;
		this.status = gm.status;
		this.question_set = new QSet(gm.question_set);
		this.teams = gm.teams;
		this.round_index = gm.round_index;
		this.last_round = gm.last_round;
		this.submissions_open = gm.submissions_open;
		this.asked = gm.asked;
		this.answered = gm.answered;
		this.empty_tables = gm.empty_tables;
		this.total_teams = gm.total_teams;
		this.total_players = gm.total_players;
		this.shift_status = gm.shift_status;
		this.first_action = gm.first_action;
		this.last_action = gm.last_action;
		this.scores = gm.scores;
		this.sortScores();
		this.sanitizeDupes();
		this.sortRounds();
		this.formatDate();
		this.hosts = gm.hosts;
		this.hosts_shift_status = gm.hosts_shift_status
		this.server_date = gm.server_date
		console.log(this);
	}

	formatDate() {
		let dt = new Date(this.date);
		let m = dt.getMonth();
		const mList = [
			"January", "February",
			"March", "April", "May",
			"June", "July", "August",
			"September", "October",
			"November", "December"
		];
		this.niceDate = `${mList[m]} ${dt.getDate()}, ${dt.getFullYear()}`
	}

	sortScores() {
		if (this.scores) {
			this.scores.sort((a: FinalScore, b: FinalScore) => {
				if (a.score > b.score) return -1;
				else if (a.score < b.score) return 1;
				else return 0;
			})
		}
	}

	getTeamFinalScore(team_code: string) {
		if (this.scores?.length) {
			for (let s of this.scores) {
				if (s.team_code === team_code) return s;
			}
		}
		return null;
	}

	addTeam(team: Team) {
		let i = this.indexOfTeam(team.team_code);
		if (i === null) this.teams.push(team);
		else this.teams.splice(i, 1, team);
	}

	resetTeamSubmissionForRound(round_id: number, team_code: string) {
		if (this.answered[`r_${round_id}`]) {
			if (this.answered[`r_${round_id}`][`t_${team_code}`]) {
				delete this.answered[`r_${round_id}`][`t_${team_code}`];
			}
		}
	}

	removeTeam(team_code: string) {
		for (let i = this.teams.length - 1; i >= 0; i--) {
			if (this.teams[i].team_code === team_code) {
				this.teams.splice(i, 1);
			}
		}
	}

	downgradeTeam(team_code: string) {
		for (let t of this.teams) {
			if (t.team_code === team_code) {
				t.manual = true;
				t.players = [];
			}
		}
	}

	indexOfTeam(team_code: string) {
		for (let i = 0; i < this.teams.length; i++) {
			if (this.teams[i].team_code === team_code) return i;
		}
		return null;
	}

	sortTeams(sortby: string, asc: boolean) {
		if (sortby === "teams") {
			this.teams.sort((a, b) => {
				if (a.team_name > b.team_name) return (asc) ? -1 : 1;
				else if (a.team_name < b.team_name) return (asc) ? 1 : -1;
				else return 0;
			});
		}
		else {
			this.teams.sort((a, b) => {
				if (this.teamScore(a.team_code) > this.teamScore(b.team_code)) {
					return (asc) ? -1 : 1;
				}
				else if (this.teamScore(a.team_code) < this.teamScore(b.team_code)) {
					return (asc) ? 1 : -1;
				}
				else return 0;
			});
		}
	}

	askedThisRound(round_id: number) {
		return this.asked['r_' + round_id];
	}

	sanitizeDupes() {
		for (let r_tag in this.asked) {
			let r = this.asked[r_tag];
			if (r && r.length > 1) {
				for (let i = r.length - 1; i >= 0; i--) {
					let q = r[i];
					for (let x = i - 1; x >= 0; x--) {
						let fq = r[x];
						if (fq.question_id === q.question_id) r.splice(i, 1);
					}
				}
			}
		}
	}

	questionIsAsked(round_id: number, question_id: number) {
		let r_asked = this.asked['r_' + round_id]
		if (r_asked?.length) {
			for (let q of r_asked) {
				if (q.question_id === question_id) return true;
			}
		}
		return false;
	}

	getQuestionByAskedIndex(r_id: number, q_idx: number) {
		let q_id = this.asked['r_' + r_id][q_idx].question_id;
		return this.getQuestionById(q_id);
	}

	getQuestionById(q_id: number) {
		for (let r of this.question_set.rounds) {
			for (let q of r.questions) {
				if (q.question_id === q_id) return q;
			}
		}
		return null;
	}

	getQuestionAnswers(round_id: number, q_idx: number) {
		let output = [];
		for (let t_id in this.answered['r_' + round_id]) {
			let sub = this.answered['r_' + round_id][t_id]
			let t_ans;
			if (sub) t_ans = sub.answers
			if (t_ans && t_ans[q_idx]) {
				output.push(t_ans[q_idx]);
			}
		}
		return output;
	}

	sortRounds() {
		this.question_set.rounds.sort((a, b) => {
			if (a.round_order > b.round_order) return 1;
			else if (a.round_order < b.round_order) return -1;
			else return 0;
		})
		if (this.asked) {
			for (let r of this.question_set.rounds) {
				this.orderByAsked(r.round_id);
			}
		}
	}

	orderByAsked(round_id: number) {
		let asked = [];
		if (this.status !== 'scheduled' && this.asked) {
			let rAsked = this.asked['r_' + round_id];
			if (rAsked) asked = rAsked;
		}
		let round = this.getRoundById(round_id);
		round.orderByAsked(asked);
	}

	getRoundById(round_id: number) {
		for (let r of this.question_set.rounds) {
			if (r.round_id === round_id) {
				return r;
			}
		}
		return null;
	}

	getRoundIdx(round_id: number) {
		for (let r of this.question_set.rounds) {
			if (r.round_id === round_id) return r.round_order;
		}
		return null;
	}

	niceTitle() {
		if (this.title === 'trivia' || this.title === 'slam') {
			return (this.title === 'trivia') ? "Pub Quiz" : "Survey Slam";
		}
		return this.title;
	}

	isAsked(round: number, question: number) {
		let r = this.asked['r_' + round]
		if (r) {
			for (let q of r) {
				if (q.question_id === question) return true;
			}
		}
		return false;
	}

	questionsLeft(round_id: number) {
		let r = this.getRoundById(round_id);
		let r_asked = this.asked['r_' + round_id];
		if (r.num_questions === 0 || !r_asked) return 1;
		else {
			return (r_asked.length >= r.num_questions)
				? 0 : r.num_questions - r_asked.length;
		}
	}

	findQuestion(question_id: number) {
		for (let r of this.question_set.rounds) {
			for (let q of r.questions) {
				if (q.question_id === question_id) return q;
			}
		}
		return null;
	}

	addAsked(round_id: number, question_id: number, idx?: number) {
		if (!this.isAsked(round_id, question_id)) {
			let q = this.findQuestion(question_id);
			let asked = {
				question_id: q.question_id,
				question_text: q.question_text,
				num_answers: q.num_answers
			};
			let r = this.asked['r_' + round_id];
			if (!r || r.length === 0) {
				r = [asked];
				this.asked['r_' + round_id] = r;
			}
			else {
				if (idx === undefined || idx === null || idx >= r.length) {
					this.asked['r_' + round_id].push(asked);
				}
				else {
					this.asked['r_' + round_id].splice(idx, 0, asked);
				}
			}
			this.orderByAsked(round_id);
		}
		this.getQuestionById(question_id).releasing = false;
	}

	removeAsked(round_id: number, question_id: number) {
		let r = this.asked['r_' + round_id]
		for (let i = 0; i < r.length; i++) {
			if (r[i].question_id === question_id) {
				this.asked['r_' + round_id].splice(i, 1);
				this.orderByAsked(round_id);
				return i;
			}
		}
		this.getQuestionById(question_id).releasing = false;
		return null;
	}

	markCorrect(answer: string, question_id: number, a_idx: number) {
		let question = this.getQuestionById(question_id);
		return question.queueUpdateVariant({ value: answer, a_idx });
		// if (typeof answer === "string") answer = {value: answer};
		// for (let i = 0; i < question.answers.length; i++) {
		// 	let ans = question.answers[i];
		// 	let found;
		// 	for (let x = ans.variations.length - 1; x >= 0 ; x--) {
		// 		if (ans.variations[x] === answer.value) {
		// 			if (i !== a_idx) ans.variations.splice(i, 1);
		// 			found = true;
		// 		}
		// 	}
		// 	if (!found && i === a_idx) ans.variations.push(answer.value);
		// }
		// for (let i = question.incorrect.length - 1; i >= 0; i--) {
		// 	if (question.incorrect[i] === answer.value) {
		// 		question.incorrect.splice(i, 1)
		// 	}
		// }
	}

	markWrong(answer: string, question_id: number) {
		let question = this.getQuestionById(question_id);
		return question.queueUpdateVariant({ value: answer, is_incorrect: true });
		// for (let a of question.answers) {
		// 	for (let i = a.variations.length - 1; i >= 0; i--) {
		// 		if (a.variations[i] === answer.value) a.variations.splice(i, 1)
		// 	}
		// }
		// let found;
		// for (let i of question.incorrect) {
		// 	if (i === answer.value) found = true;
		// }
		// if (!found) question.incorrect.push(answer.value);
	}

	replaceQuestionOfId(q_id: number, question: Question) {
		for (let r of this.question_set.rounds) {
			for (let i = r.questions.length - 1; i >= 0; i--) {
				if (r.questions[i].question_id === q_id) {
					r.questions[i] = question;
					return;
				}
			}
		}
	}

	teamScore(team_code: string) {
		if (this.status == "ended" || this.status == 'finalized') {
			for (let s of this.scores) {
				if (s.team_code == team_code) return s.score;
			}
		}
		let score = 0;
		for (let r_key in this.answered) {
			let t_sub = this.answered[r_key][`t_${team_code}`];
			if (!t_sub) continue;
			if (t_sub.score !== null && t_sub.score !== undefined) {
				score += t_sub.score;
				continue;
			}
			let r_id_str = r_key.substr(2);
			let r_id = parseInt(r_id_str, 10);
			let breakdown = this.answerPipe.transform(this, r_id, team_code);
			let missedOne = false;
			let r_score = 0;
			for (let q of breakdown) {
				for (let a of q) {
					if (!a.correct) missedOne = true;
					r_score += a.score;
				}
			}
			if (t_sub.doubled) {
				r_score = (missedOne) ? 0 : r_score * 2;
			}
			let overrideScore = t_sub.score;
			if (overrideScore !== undefined && overrideScore !== null) {
				r_score = overrideScore;
			}
			score += r_score;
		}
		return score;
	}

	teamScoreForRound(team_code: string, round_id: number) {
		if (!this.answered['r_' + round_id]) return 0;
		let r = this.getRoundById(round_id);
		let r_score = 0;
		let r_ans = this.answered['r_' + round_id][`t_${team_code}`];
		// if (r_ans && r_ans.score !== null && r_ans.score !== undefined) {
		// 	return r_ans.score;
		// }
		let doubled;
		if (r_ans) {
			doubled = r_ans.doubled;
		}
		let missedOne = false;
		let brkdwn = this.answerPipe.transform(this, round_id, team_code);
		if (!brkdwn) return 0;
		for (let q of brkdwn) {
			for (let a of q) {
				if (r.double_or_nothing && !a.correct) missedOne = true;
				r_score += a.score;
			}
		}
		if (r.double_or_nothing && doubled) {
			r_score = (missedOne) ? 0 : r_score * 2;
		}
		return r_score;
	}

	teamOverrideScoreForRound(team_code: string, round_id: number) {
		let r_ans = this.answered[`r_${round_id}`];
		if (!r_ans) return undefined;
		let t_ans = r_ans[`t_${team_code}`];
		if (!t_ans || t_ans.score === null || t_ans.score === undefined) return undefined;
		else return t_ans.score;

	}

	teamDoubledRound(team_code: string, round_id: number) {
		let answered = this.answered['r_' + round_id];
		if (!answered) return null;
		let t_answers = answered[`t_${team_code}`];
		return (t_answers) ? t_answers.doubled : false;
	}

	teamSubmittedRound(round_id: number, team_code: string) {
		if (this.answered['r_' + round_id]) {
			if (this.answered['r_' + round_id]['t_' + team_code]) {
				return true;
			}
		}
		return false;
	}

	remainingSubmits(round_id: number) {
		let count = 0;
		for (let t of this.teams) {
			if (this.teamSubmittedRound(round_id, t.team_code)) {
				count++;
			}
		}
		return count;
	}

	activeTeams() {
		let count = 0;
		for (let t of this.teams) {
			if (!t.manual) {
				count++;
			}
		}
		return count;
	}

	findTeamById(team_code: string) {
		for (let t of this.teams) {
			if (t.team_code === team_code) {
				return t;
			}
		}
		return null;
	}

	getQuestionResponses(round_id: number, asked_idx: number) {
		let r_ans = this.answered['r_' + round_id];
		let responses = [];
		for (let key in r_ans) {
			if (r_ans[key].answers && r_ans[key].answers[asked_idx]) {
				let team = this.findTeamById(key.substring(2));
				if (team) responses.push({
					answers: r_ans[key].answers[asked_idx],
					team_name: team.team_name
				});
			}
		}
		return responses;
	}

	getTeamOfPlayer(player_code: string) {
		for (let team of this.teams) {
			for (let plyr of team.players) {
				if (plyr.player_code === player_code) return team;
			}
		}
		return;
	}

	numPlayers() {
		let pl = 0;
		for (let t of this.teams) {
			pl += Math.max(t.num_players, t.players.length)
		}
		return pl;
	}
}
