<div class="round-box">
	<div class="round-header clickable" [ngClass]="{'live': game.round_index === round.round_id}">
		<div *ngIf="expanded" class="vert-center" (click)="activeModal = 'submitList'">
			<i class="material-icons clickable big-icon">group</i>
			<span>{{game.remainingSubmits(round.round_id)}}/{{game.teams.length}}</span>
		</div>
		<h3 [ngClass]="{'center-text': expanded}" (click)="collapseRound()">
			<span *ngIf="!round.tiebreaker">Round {{round.round_order + 1}}</span>
			<span *ngIf="!expanded && !round.tiebreaker">: </span>
			<br *ngIf="expanded && !round.tiebreaker">
			<span>{{round.title}}</span>
			<span *ngIf="expanded"><br>{{round.subtitle}}</span>
		</h3>
		<div class="vert-center" (click)="collapseRound()">
			<i *ngIf="!expanded" class="material-icons">unfold_more</i>
			<i *ngIf="expanded" class="material-icons big-icon">unfold_less</i>
		</div>
		<div *ngIf="!expanded" class="round-expander" (click)="expandRound()"></div>
	</div>

	<div [ngClass]="{'hidden': !expanded}" class="round-content">
		<p *ngIf="round.player_instructions" class="center-text instructions">{{round.player_instructions}}</p>
		<p class="center-text">
			<span *ngIf="round.handout">This is a Handout Round.<br></span>
			<span *ngIf="round.single_answer">This is a Clue Round.<br></span>
			<span *ngIf="round.tiebreaker">This is a Tiebreaker Round.<br></span>

			<span *ngIf="!round.tiebreaker && !round.single_answer && !round.handout">Read each question twice, with a brief pause between.<br></span>
			<span *ngIf="!round.tiebreaker && round.single_answer">Read each clue twice, allow one minute between each clue.<br>Only one guess per team.<br></span>

			<span *ngIf="(round.handout || round.questions[0]?.num_answers > 1)">Allow 10 minutes to submit answers<br></span>
			<span *ngIf="!(round.handout || round.questions[0]?.num_answers > 1) && !round.single_answer && !round.tiebreaker">Allow 5 minutes to submit answers<br></span>

			<span *ngIf="!round.tiebreaker">
				<span>Double or Nothing</span>
				<span *ngIf="round.double_or_nothing"> IS</span>
				<span *ngIf="!round.double_or_nothing"> IS NOT</span>
				<span> in play.</span>
			</span>
		</p>

		<div class="start-stop-box">
			<button *ngIf="game.round_index === round.round_id && !game.submissions_open" class="w-100 basic-btn-red round-btn" (click)="endRound()" [ngClass]="{'btn-disable': loading}">
				<i class="material-icons">{{round.asked.length > 0 ? 'stop' : 'block'}}</i>
				<span>{{round.asked.length > 0 ? 'End Round' : 'Cancel Round'}}</span>
			</button>
			<button *ngIf="game.round_index !== round.round_id" class="basic-btn-green round-btn" (click)="validateStartRound()" [ngClass]="{'btn-disable': loading || game.round_index}">
				<i class="material-icons">play_arrow</i>
				<span>Start Round</span>
			</button>
			<div *ngIf="game.round_index === round.round_id && game.submissions_open && !round.single_answer">
				<button class="w-100 round-btn" (click)="toggleSubmissions()" [ngClass]="{'basic-btn-green': !game.submissions_open, 'basic-btn-red': game.submissions_open, 'btn-disable': loading}">
					<i class="material-icons">{{game.submissions_open ? 'stop' : 'traffic'}}</i>
					<span *ngIf="!game.submissions_open">
						<span *ngIf="!round.handout">Open Submissions</span>
						<span *ngIf="round.handout">Release All Questions</span>
					</span>
					<span *ngIf="game.submissions_open">Close Submissions</span>
				</button>
			</div>
		</div>

		<app-question *ngFor="let q of round.questions; let i = index" #qlist [question]="q" [round]="round" [game]="game" [q_index]="i" (score)="startScoring($event)" (preview)="startPreviewQuestion($event)" (resume)="openSubmissions()"></app-question>

		<div *ngIf="round.announcement" class="std-m announce">
			<strong>Announcement: </strong>
			<span> {{round.announcement}}</span>
		</div>

		<div *ngIf="game.round_index === round.round_id">
			<button *ngIf="!round.single_answer" class="w-100 round-btn" (click)="toggleSubmissions()" [ngClass]="{'basic-btn-green': !game.submissions_open, 'basic-btn-red': game.submissions_open, 'btn-disable': loading}">
				<i class="material-icons">{{game.submissions_open ? 'stop' : 'traffic'}}</i>
				<span *ngIf="!game.submissions_open">
					<span *ngIf="!round.handout">Open Submissions</span>
					<span *ngIf="round.handout">Release All Questions</span>
				</span>
				<span *ngIf="game.submissions_open">Close Submissions</span>
			</button>
			<button *ngIf="round.single_answer && round.asked.length > 0" class="round-btn basic-btn-red w-100" (click)="toggleSubmissions()">
				<i class="material-icons">stop</i>
				<span>End Round</span>
			</button>
		</div>
	</div>
</div>

<div *ngIf="activeModal === 'noQuestionsReleased'" class="modal-background buffer">
	<div class="click-layer" (click)="activeModal = ''"></div>
	<div class="modal-container">
		<div class="hrz-between">
			<h2>No Questions Released!</h2>
			<i class="material-icons clear clickable" (click)="activeModal = ''">clear</i>
		</div>
		<p>Please release questions before opening submissions.</p>
		<div class="hrz-end">
			<button class="basic-btn-grey std-mr" (click)="activeModal = ''">Go Back</button>
		</div>
	</div>
</div>

<div *ngIf="activeModal === 'previewQuestion'" class="modal-background buffer">
	<div class="click-layer" (click)="activeModal = ''"></div>
	<div class="modal-container vert-between h-100">
		<div class="overflow-scroll">
			<div class="hrz-between">
				<h2 class="no-mt">Round {{round.round_order + 1}} Questions</h2>
				<i class="material-icons clear clickable" (click)="activeModal = ''">clear</i>
			</div>
			<!-- <div class="hrz-between std-mt">
				<i class="material-icons clickable" (click)="prevPreview()">arrow_back_ios</i>
				<h3 class="no-m">{{previewIdx >= round.num_questions ? 'Extra ' : ''}}{{round.single_answer ? 'Clue' : 'Question'}} {{previewIdx >= round.num_questions ? previewIdx + 1 - round.num_questions : previewIdx + 1}} {{round.single_answer ? '(' + previewQ.question.score + 'points)' : ''}}</h3>
				<i class="material-icons clickable" (click)="nextPreview()">arrow_forward_ios</i>
			</div> -->
			<div *ngIf="!game.isAsked(round.round_id, previewQ.question.question_id)">
				<h3 class="no-m" class="center-text">{{previewIdx >= round.num_questions ? 'Extra ' : ''}}{{round.single_answer ? 'Clue' : 'Question'}} {{previewIdx >= round.num_questions ? previewIdx + 1 - round.num_questions : previewIdx + 1}} {{round.single_answer ? '(' + previewQ.question.score + 'points)' : ''}}</h3>
				<p class="lg-mb md-text std-mt">
					<strong>{{previewQ.question.question_text}}</strong>
					<em *ngIf="previewQ.question.question_note"><br>{{previewQ.question.question_note}}</em>
				</p>
			</div>
			<app-score-question *ngIf="game.isAsked(round.round_id, previewQ.question.question_id)" [game]="game" [round]="round" [question]="previewQ.question" [q_idx]="previewIdx"></app-score-question>
			<div class="bumper"></div>
		</div>
		<div *ngIf="!game.isAsked(round.round_id, previewQ.question.question_id)" class="std-mt">
			<button *ngIf="previewQ.releasable()" class="basic-btn-green w-100" (click)="previewQ.askQuestion()">
				<i class="material-icons">campaign</i>
				<span>{{previewQ.question.releasing ? 'Releasing...' : 'Release '}}{{round.single_answer ? 'Clue' : 'Question'}}</span>
			</button>
			<button *ngIf="!previewQ.releasable()" class="basic-btn-grey w-100" [disabled]="true">{{game.isAsked(round.round_id, previewQ.question.question_id) ? 'Released' : 'Unavailable'}}</button>
		</div>
		<div *ngIf="game.isAsked(round.round_id, previewQ.question.question_id)" class="std-mt">
			<div *ngIf="!game.submissions_open" class="hrz-between">
				<button class="basic-btn-grey w-100 sm-mr" (click)="activeModal = ''">
					<span>Close</span>
				</button>
				<button *ngIf="previewIdx + 1 < round.questions.length" class="basic-btn-green w-100 sm-ml" (click)="nextPreview()">
					<i class="material-icons">skip_next</i>
					<span>Next Question</span>
				</button>
			</div>
			<button *ngIf="game.submissions_open" class="basic-btn-red w-100" (click)="round.asked.length >= round.num_questions ? closeSubmissions() : pauseSubmissions()">
				<i class="material-icons">pause</i>
				<span>{{round.asked.length >= round.num_questions ? 'Close' : 'Pause'}} Submissions</span>
			</button>
		</div>
	</div>
</div>

<div *ngIf="activeModal === 'scoring'" class="modal-background buffer">
	<div class="click-layer" (click)="activeModal = ''"></div>
	<div class="modal-container">
		<div class="hrz-between">
			<h2 class="no-mt">Round {{round.round_order + 1}} Scoring</h2>
			<i class="material-icons clear clickable" (click)="activeModal = ''">clear</i>
		</div>
		<div class="hrz-between std-mt">
			<i class="material-icons clickable" (click)="prevQuestion()">arrow_back_ios</i>
			<h3 class="no-m">Question {{scoreQIdx + 1}}</h3>
			<i class="material-icons clickable" (click)="nextQuestion()">arrow_forward_ios</i>
		</div>
		<app-score-question [game]="game" [round]="round" [question]="scoreQ" [q_idx]="scoreQIdx"></app-score-question>
	</div>
</div>

<div *ngIf="activeModal === 'submitList'" class="modal-background">
	<div class="click-layer" (click)="activeModal = ''"></div>
	<div class="modal-container">
		<div class="hrz-between">
			<h2 *ngIf="!round.tiebreaker" class="no-mt">Round {{round.round_order + 1}} Summary</h2>
			<h2 *ngIf="round.tiebreaker" class="no-mt">Tiebreaker Summary</h2>
			<i class="material-icons clear clickable" (click)="activeModal = ''">clear</i>
		</div>
		<app-round-summary [game]="game" [round]="round" (resetForTeam)="resetTeamSubmissions($event)"></app-round-summary>
	</div>
</div>

<div *ngIf="activeModal === 'reviewAnswers'" class="modal-background">
	<div class="click-layer" (click)="activeModal = ''"></div>
	<div class="modal-container">
		<div class="hrz-between">
			<h2 class="no-mt">Answers needing review</h2>
			<i class="material-icons clear clickable" (click)="activeModal = ''">clear</i>
		</div>
		<p>You still have some unreviewed answers from previous round(s):</p>
		<ul>
			<li *ngFor="let r of game.needsReview" [ngClass]="{'hidden': r.round.round_order === round.round_order}">
				<strong>Round {{r.round.round_order + 1}} ({{r.needsReview}} answers needing review)</strong>
			</li>
		</ul>
		<p>We strongly recommend reviewing all answers before moving on to the next round. Leaving answers unreviewed can cause scores to be calculated incorrectly.</p>
		<div class="hrz-end">
			<button class="basic-btn-grey std-mr" (click)="activeModal = ''">Cancel</button>
			<button class="basic-btn-red" (click)="startRound()">Start Round Anyway</button>
		</div>
	</div>
</div>

<div *ngIf="activeModal === 'askAllAnswers'" class="modal-background">
	<div class="click-layer" (click)="activeModal = ''"></div>
	<div class="modal-container">
		<div class="hrz-between">
			<h2 class="no-mt">Not All Questions Released</h2>
			<i class="material-icons clear clickable" (click)="activeModal = ''">clear</i>
		</div>
		<p>You have only released {{numAsked()}} of the {{round.num_questions}} questions expected for this round. <strong>Allowing submissions before you have asked all questions could result in teams submitting partial answers for the round.</strong></p>
		<p>We STRONGLY recommend waiting until ALL questions are released before you open submissions!</p>
		<div class="hrz-end">
			<button class="basic-btn-grey std-mr" (click)="activeModal = ''">Cancel</button>
			<button class="basic-btn-red" (click)="openSubmissions()">Open Submissions Anyway</button>
		</div>
	</div>
</div>

<div *ngIf="activeModal === 'mustCloseRound'" class="modal-background">
	<div class="click-layer" (click)="activeModal = ''"></div>
	<div class="modal-container">
		<div class="hrz-between">
			<h2 class="no-mt">A Round is Still Active</h2>
			<i class="material-icons clear clickable" (click)="activeModal = ''">clear</i>
		</div>
		<p>Round {{game.getRoundIdx(game.round_index) + 1}} is currently active. Please end the current round before attempting to start a new one.</p>
		<div class="hrz-end">
			<button class="basic-btn-grey std-mr" (click)="activeModal = ''">Go Back</button>
		</div>
	</div>
</div>
