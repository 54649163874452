import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GameListComponent } from './game-list/game-list.component';
import { GameDetailComponent } from './game-detail/game-detail.component';
import { LiveGameComponent } from './live-game/live-game.component';
import { ProfileComponent } from './profile/profile.component';
import { AdminViewComponent } from './admin-view/admin-view.component';
import { ReferralComponent } from './referral/referral.component';
import { TechSupportComponent } from './tech-support/tech-support.component';
import { MiscLinksComponent } from './misc-links/misc-links.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
	{ path: '', component: GameListComponent, canActivate: [AuthGuard] },
	{ path: 'login', component: AuthComponent },
	{ path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
	{ path: 'admin', component: AdminViewComponent, canActivate: [AuthGuard] },
	{ path: 'referral', component: ReferralComponent, canActivate: [AuthGuard] },
	{ path: 'support', component: TechSupportComponent, canActivate: [AuthGuard] },
	{ path: 'links', component: MiscLinksComponent, canActivate: [AuthGuard] },
	{ path: 'game/:id/detail', component: GameDetailComponent, canActivate: [AuthGuard] },
	{ path: 'game/:id/live', component: LiveGameComponent, canActivate: [AuthGuard] },
	{ path: 'forgot-password', component: ForgotPasswordComponent },
	{ path: 'new-password/:token', component: NewPasswordComponent },
	{ path: '**', redirectTo: '' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
