import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GameService } from '../services/game.service';
import { LocalTimeService } from '../services/local-time.service';
import { Game } from '../classes/Game';
import { AuthService } from '../services/auth.service';

@Component({
	selector: 'app-game-detail',
	templateUrl: './game-detail.component.html',
	styleUrls: ['./game-detail.component.css']
})
export class GameDetailComponent implements OnInit {

	game: Game;

	loading: string;
	err = "";

	recaponly = false;
	gameDay: boolean = false

	constructor(
		private route: ActivatedRoute,
		private gameServe: GameService,
		private router: Router,
		private timeServe: LocalTimeService,
    private authServe: AuthService
	) { }

	ngOnInit(): void {
		this.route.queryParams.subscribe(
			(qp: Params) => {
				if (qp.recaponly) {
					this.recaponly = true;
				}
				this.route.params.subscribe((p: Params) => {
					this.getGameDetail(p.id);
				})
			})

	}

	getGameDetail(game_id: number) {
		this.loading = "Fetching Your Game...";
		this.err = "";
		let obs = this.gameServe.getGameDetail(game_id)
			.subscribe((res: any) => {
				this.game = new Game(res);

				this.gameDay = this.timeServe.compareDatetimes(this.game.server_date, this.game.date)

				if (this.recaponly) this.startGame();
				else this.loading = "";
				this.game.question_set.rounds.sort((a, b) => a.round_order - b.round_order)
				this.game.question_set.rounds.forEach(round => {
					round.questions.sort((a, b) => a.question_order - b.question_order);
				});
			},
				err => {
					this.err = err.error.detail;
					this.loading = "";
				})
	}

	startGame() {

		this.loading = this.recaponly ? "Creating Recap..." : "Starting the Game...";
		this.err = "";
		let obs = this.gameServe.startGame(this.game.kt_id, this.recaponly)
			.subscribe((res: any) => {
        // Request a new token
        this.requestNewToken();
				window.location.href = `${location.origin}/game/${res.gameid}/live`;
			},
				err => {
					this.err = err.error.detail;
					this.loading = "";
				})
	}


  requestNewToken(){
   this.authServe.requestRefreshToken()
   .subscribe((res: any) => {
    if( res?.access_token ){
      this.authServe.setToken(res.access_token);
    }
   })
  }

}
