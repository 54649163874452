<h2 *ngIf="loading" class="loading-message">Finding Your Games...</h2>

<h2 *ngIf="err" class="error-message">{{err}}</h2>

<div *ngIf="!loading" class="routed-container">
	<div class="routed-content">
		<h1 class="title-message">Your Games</h1>
		<p class="game-list-header">Live and Upcoming Games</p>
		<app-game-stub *ngFor="let g of games" [game]="g"></app-game-stub>
		<p class="game-list-header">Past Games</p>
		<app-game-stub *ngFor="let p of pastGames" [game]="p"></app-game-stub>
	</div>
</div>