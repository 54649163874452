
<div class="menu-container">
	<div class="menu-background" (click)="close.emit(true)"></div>
	<div class="menu-box">
		<div class="close-box">
			<i (click)="emitClose()" class="material-icons">clear</i>
		</div>
		<div class="nav-list">
			<ul>
				<li (click)="emitNav(['/'])">My Games</li>
				<li (click)="emitNav(['/profile'])">QM Profile</li>
				<li (click)="emitNav(['/referral'])">Referral Program</li>
				<li (click)="emitNav(['/links'])">Additional Links</li>
				<!-- <li>
					<a href="https://share.hsforms.com/12cDVH6k7QUaM1Ye3gwizVw5cu2n" target="_blank">Request Time Off</a>
				</li> -->
				<a href="http://host.kingtrivia.com/support">
					<li>Get Help</li>
				</a>
				<a href="https://admin.kingtrivia.com/games/list" target="_blank">
					<li *ngIf="user.admin">Admin View</li>
				</a>
				<li (click)="emitLogout()">Log Out</li>
			</ul>
		</div>
		<div class="version-msg">
			<span>Version {{ver}}</span>
		</div>
	</div>
</div>