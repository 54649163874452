import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Game } from '../classes/Game';
import { Round } from '../classes/QSet';
import { TeamsForRoundSummaryPipe } from '../teams-for-round-summary.pipe';

@Component({
  selector: 'app-round-summary',
  templateUrl: './round-summary.component.html',
  styleUrls: ['./round-summary.component.css']
})
export class RoundSummaryComponent implements OnInit {

	@Input() game: Game;
	@Input() round: Round;

	@Output() resetForTeam: EventEmitter<string> = new EventEmitter();

	sortType = 'teamName';
	asc: boolean;

	constructor() { }

	ngOnInit(): void {
	}

	passReset(team_code: string) {
		this.resetForTeam.emit(team_code)
	}

	sortBy(type: string) {
		if (type !== this.sortType) this.sortType = type;
		else this.asc = !this.asc;
	}

}
