<div class="image-carousel--container">
	<div *ngIf="displayImages.length; else noImage" class="image-carousel--image-queue">
		<div class="image-carousel--image-box" *ngFor="let i of displayImages; let idx = index">
			<img [ngClass]="i.orientation" [src]="i.url">
		</div>
	</div>
	<div *ngIf="displayImages.length" class="image-carousel--img-ctrl">
		<div (click)="decrementIdx()">
			<i class="material-icons">arrow_circle_left</i>
		</div>
		<div (click)="incrementIdx()">
			<i class="material-icons">arrow_circle_right</i>
		</div>
	</div>
</div>

<ng-template #noImage>
	<h3>No Images Selected</h3>
</ng-template>
