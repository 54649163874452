import { Component, OnInit, Input } from '@angular/core';
import { Game, FinalScore } from '../classes/Game';
import { Team } from '../classes/Team';
import { GameService } from '../services/game.service';

@Component({
  selector: 'app-team-management',
  templateUrl: './team-management.component.html',
  styleUrls: ['./team-management.component.css']
})
export class TeamManagementComponent implements OnInit {

	@Input() game: Game;
	@Input() noWS: boolean;
	@Input() sortby: string = 'score';
	@Input() asc: boolean = true;
	@Input() instantUpdateFinalScore: boolean;

	teamDetailIdx: number;

	newTeam: Team;
	addingTeam: boolean;

	constructor(private gameServe: GameService) { }

	ngOnInit(): void {
		this.game.sortTeams(this.sortby, this.asc);
	}

	teamSort() {
		this.teamDetailIdx = null;
		if (this.sortby === 'teams') this.asc = !this.asc;
		else this.sortby = 'teams';
		this.game.sortTeams(this.sortby, this.asc);
	}

	scoreSort() {
		this.teamDetailIdx = null;
		if (this.sortby === 'score') this.asc = !this.asc;
		else this.sortby = 'score';
		this.game.sortTeams(this.sortby, this.asc);
	}

	startNewTeam() {
		this.newTeam = {
			kt_team_id: 0,
			players: [],
			team_name: "",
			quota: 0,
			manual: true,
			num_players: 1
		};
	}

	addTeam() {
		this.addingTeam = true;
		this.gameServe.addTeam(this.game.gameid, this.newTeam.kt_team_id, this.newTeam.team_name)
			.subscribe(
				(res: {success: boolean, team: Team}) => {
					if (this.noWS) {
						this.game.addTeam(res.team)
					}
					this.newTeam = null;
					this.addingTeam = false;
				},
				err => {
					this.addingTeam = false;
					console.log(err);
				});

	}

}
