import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Game } from '../classes/Game';
import { User } from '../classes/User';
import { GameService } from '../services/game.service';
import { AuthService } from '../services/auth.service';
import { ShiftService } from '../services/shift.service';
import { ActivatedRoute, Params } from '@angular/router';
import { combineLatest } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { GameRecapStatsComponent } from "../game-recap-stats/game-recap-stats.component";
import { GameRecapShiftComponent } from "../game-recap-shift/game-recap-shift.component";
import { GameRecapPublicComponent } from "../game-recap-public/game-recap-public.component";

@Component({
	selector: 'app-game-recap',
	templateUrl: './game-recap.component.html',
	styleUrls: ['./game-recap.component.css']
})
export class GameRecapComponent implements OnInit {

	@Input() user: User;
	@Input() game: Game;
	@Input() shift: Shift;

	@Output() recapClose: EventEmitter<boolean> = new EventEmitter();
	@Output() shiftEnd: EventEmitter<string> = new EventEmitter();
	@Output() gameEnd: EventEmitter<boolean> = new EventEmitter();
	@Output() shiftChange: EventEmitter<Shift> = new EventEmitter();

	@ViewChild(GameRecapStatsComponent) statsComponent: GameRecapStatsComponent;
	@ViewChild(GameRecapShiftComponent) shiftComponent: GameRecapShiftComponent;
	@ViewChild(GameRecapPublicComponent) publicComponent: GameRecapPublicComponent;

	loading = "";
	err: string;
	verifying: "stats" | "shift" | "public";
	activeModal: string = ""
	expectedWork: number;
	totalWork: number;

	publicMsgs: string[] = [];

	editMode: '' | 'stats' | 'shift' | 'public' = '';

	constructor(
		private gameServe: GameService,
		private router: Router,
		private route: ActivatedRoute,
		private auth: AuthService,
		private shiftServe: ShiftService) { }

	ngOnInit(): void {
		console.log(this.game)
	}

	encodedVenue() {
		let encoded = encodeURIComponent(this.game.venue)
		return encoded;
	}

	backToGame() {
		this.recapClose.emit(true);
	}

	toggleNoShow() {
		this.shift.no_show = !this.shift.no_show;
		this.updateShift(this.shift);
	}

	shiftStatus() {
		switch (this.shift?.status) {
			case 'draft':
				return "Recap is in draft. Don't forget to Publish!";

			case 'submitted':
				return "Your recap is submitted and awaiting approval";

			case 'review':
				return `Your recap needs revision: ${this.shift.blog_feedback}`

			case 'approved':
				return "Your recap is approved!";

			default:
				return "Game is in progress or not yet started";
		}
	}

	cancelVerify() {
		this.verifying = undefined;
		this.publicMsgs = [];
	}

	async startVerify(type: 'stats' | 'shift' | 'public') {
		if (this.loading || this.editMode) return;
		this.verifying = undefined;
		const componentName = `${type}Component`;
		if (this[componentName]) {
			console.log(`Verifying ${componentName}`);
			let msgs = await this[componentName].verify();
			if (msgs.length) {
				if (type === 'public') {
					this.publicMsgs = msgs;
					return;
				}
				this.verifying = type;
				return;
			}
		}
		else console.log(`${componentName} not found`);
		if (type === 'stats') this.startVerify('shift');
		else if (type === 'shift') this.startVerify('public');
		else this.publish();
	}


	publish() {
		this.shift.status = "submitted";
		this.submitShift();
	}

	afterUpdate() {
		if (this.verifying === 'stats') {
			setTimeout(() => this.startVerify('shift'), 100);
		}
		else if (this.verifying === 'shift') {
			setTimeout(() => this.startVerify('public'), 100);
		}
		else if (this.verifying === 'public') {
			setTimeout(() => this.publish(), 100);
		}
		this.verifying = undefined;
	}

	updateShift(data: Shift | ShiftStats) {
		this.loading = "Updating..."
		this.shiftServe.editGameShift(this.shift.shiftid, data)
			.pipe(finalize(() => {
				this.afterUpdate();
				this.loading = "";
			}))
			.subscribe(
				(res: Shift) => {
					this.shift = res;
					this.shiftChange.emit(res);
					if (this.editMode) this.editMode = "";
				},
				err => this.err = err.error.detail)
	}

	submitShift() {
		this.loading = "Updating..."
		this.shiftServe.submitShift(this.shift.shiftid)
			.pipe(finalize(() => {
				this.afterUpdate();
				this.loading = "";
			}))
			.subscribe(
				(res: Shift) => {
					this.shift = res;
					this.shiftChange.emit(res);
					if (this.editMode) this.editMode = "";
				},
				err => this.err = err.error.detail)
	}

	updateTimeWorked(data: WorkTime[]) {
		if (data.length) {
			let w = data.shift();
			if (w.work_code) {
				if (w.markDelete) this.deleteWorkBlock(w.work_code, data);
				else {
					let orig = this.getWorkBlock(w.work_code);
					if (!this.compareWorkBlocks(w, orig)) {
						this.updateWorkBlock(w, data);
					}
					else if (data.length) this.updateTimeWorked(data);
				}
			}
			else if (!w.markDelete) {
				this.createWorkBlock(w, data);
			}
			else if (data.length) this.updateTimeWorked(data);
		}
		else {
			if (this.shiftComponent) this.shiftComponent.resetTimes();
		}
		this.editMode = "";
		this.afterUpdate();
	}

	deleteWorkBlock(work_code: string, data: WorkTime[] = []) {
		this.loading = "Updating..."
		this.shiftServe.deleteWorkBlock(this.shift.shiftid, work_code)
			.pipe(finalize(() => this.loading = ""))
			.subscribe(
				(res: Shift) => {
					if (data.length) {
						this.updateTimeWorked(data);
					}
					else {
						this.shift = res;
						this.shiftChange.emit(res);
						this.afterUpdate();
					}
				},
				err => this.err = err.error.detail)
	}

	updateWorkBlock(block: WorkTime, data: WorkTime[]) {
		this.loading = "Updating..."
		this.shiftServe.editWorkBlock(this.shift.shiftid, block)
			.pipe(finalize(() => this.loading = ""))
			.subscribe(
				(res: Shift) => {
					if (data.length) {
						this.updateTimeWorked(data);
					}
					else {
						this.shift = res;
						this.shiftChange.emit(res);
						this.afterUpdate();
					}
				},
				err => this.err = err.error.detail)
	}

	createWorkBlock(block: WorkTime, data: WorkTime[]) {
		this.loading = "Updating..."
		this.shiftServe.newWorkBlock(this.shift.shiftid, block)
			.pipe(finalize(() => this.loading = ""))
			.subscribe(
				(res: Shift) => {
					if (data.length) {
						this.updateTimeWorked(data);
					}
					else {
						this.shift = res;
						this.shiftChange.emit(res);
						this.afterUpdate();
					}
				},
				err => this.err = err.error.detail)
	}

	getWorkBlock(work_code: string) {
		let out = null;
		for (let w of this.shift.work) {
			if (w.work_code === work_code) {
				out = w;
				break;
			}
		}
		return out;
	}

	compareWorkBlocks(a: WorkTime, b: WorkTime) {
		for (let k of ['start_work', 'end_work', 'description']) {
			if (a[k] !== b[k]) return false;
		}
		return true;
	}

	endShift() {
		this.shiftEnd.emit();
	}

	endGame() {
		this.gameEnd.emit(true)
	}

}


