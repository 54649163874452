import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  rootUri = environment.root_uri ?? "";

  private notificationsSubject = new BehaviorSubject<HostNotification[]>([])
  private fetchingSubject = new BehaviorSubject<boolean>(false)

  notifications$: Observable<HostNotification[]>
  fetching$: Observable<boolean>

  constructor(private http: HttpClient) {
    this.notifications$ = this.notificationsSubject.asObservable()
    this.fetching$ = this.fetchingSubject.asObservable()
  }


  assignAll(notifications: HostNotification[]): void{
    this.notificationsSubject.next(notifications);
  }

  updateNotifications(notification: HostNotification) {
    let notifications = [...this.notificationsSubject.getValue()]

    notifications.sort((a, b) => {

      const seenAtA = a.seen_at ? new Date(a.seen_at).getTime() : Number.MAX_SAFE_INTEGER;
      const seenAtB = b.seen_at ? new Date(b.seen_at).getTime() : Number.MAX_SAFE_INTEGER;

      return seenAtB - seenAtA;
    });

    const indexToUpdate = notifications.findIndex((notif) => notif.notificationid == notification.notificationid)

    if (indexToUpdate > -1) {
      notifications[indexToUpdate] = notification
      this.notificationsSubject.next(notifications)
    } else {
      this.notificationsSubject.next([notification, ...notifications])
    }


  }

  readNotification(notification: HostNotification): Observable<HostNotification> {
    return this.http.post<HostNotification>(`${this.rootUri}/api/host/notifications/${notification.notificationid}/read`, {})
      .pipe(
        map((response: any) => {

          return {
            notificationid: response.notificationid,
            title: response.title,
            body: response.body,
            sent_at: response.created_on,
            seen_at: response.seen_by[localStorage.getItem('userid')]
          } as HostNotification
        })
      )
  }

  readableDate(date: string): String {
    const today: Date = new Date()
    let d: Date = new Date(date)
    let value: string = ""

    return d.toLocaleDateString() === today.toLocaleDateString() ? "Today" : d.toLocaleDateString()
  }
}
