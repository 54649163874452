import { Component, OnInit } from '@angular/core';
import { User } from '../classes/User';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

	user: User;
	err = "";
	loading = true;
	editVenmo: boolean;
	
	constructor(private authServ: AuthService) { }

	ngOnInit(): void {
		this.authServ.getHostDetail()
			.subscribe((res: any) => {
				this.user = new User(res);
				this.loading = false;
			})
	}

	updateUser(update: any) {
		this.loading = true
		this.authServ.updateUser(update)
			.subscribe(res => {
				this.user = new User(res);
				localStorage.setItem("user_detail", JSON.stringify(this.user))
				this.loading = false;
				this.editVenmo = false;
			},
			err => {
				this.loading = false;
				this.err = err.error.detail;
			})
	}	

}
