import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {

	@Input() size: 'small' | 'medium' | 'large' = 'small';
	@Input() direction: 'row' | 'column' = 'column';
	@Input() message: string;

	constructor() { }

	ngOnInit(): void {

	}

}
