<div class="dropdown">
    <span class="icon-container" (click)="toggleDropdown()">
        <i class="target-notif material-icons">notifications</i>
        <span class="notification-badge" *ngIf="unreadNotifications.length">{{ unreadNotifications.length }}</span>
    </span>
    <div id="notification-dropdown" [ngClass]="{'show': isDropdownOpen}" class="dropdown-content"
        (click)="onDropdownClick($event)">
        <ng-container *ngTemplateOutlet="fetching ? loader : notificationList">
        </ng-container>
    </div>
</div>

<app-basic-modal *ngIf="showNotification" [title]="selectedNotification.title" [message]="selectedNotification.body"
    (doCancel)="showNotification=false" cancel="Close" hideX="true" [staticBackground]="false">

</app-basic-modal>

<ng-template #notificationList>
    <h4 class="unread-title">New Notifications</h4>
    <ng-container *ngIf="unreadNotifications.length; else noNewNotifications">
        <a (click)="viewNotification(notification)" *ngFor="let notification of unreadNotifications" class="unread">
            {{ notification.title }}
            <span class="sent_date">{{ readableDate(notification.sent_at) }}</span>
        </a>
    </ng-container>

    <h4 class="previous-title">Previous Notifications</h4>
    <a (click)="viewNotification(notification)" *ngFor="let notification of readNotifications">
        {{ notification.title }}
        <span class="sent_date">{{ readableDate(notification.sent_at) }}</span>
    </a>
</ng-template>

<ng-template #loader>
    <p class="p-10 center-text ">Please wait...</p>
</ng-template>

<ng-template #noNewNotifications>
    <span class="center-text flex items-center justify-center p-15"><i
            class="material-icons font-18 text-gray-400 ">check_circle</i> <span class="text-gray-500">All
            Caught Up</span></span>
</ng-template>