<div class="game-recap--container">
	<div *ngIf="!loading; else loadMsg">
		<div class="game-recap--header">
			<h1>{{game.venue}}</h1>
			<div class="game-recap--sub-header">
				<h3>{{game.niceDate}}</h3>
				<div *ngIf="game.status !== 'live'" (click)="toggleNoShow()">
					<input type="checkbox" [(ngModel)]="shift.no_show">
					<label>Show Did NOT Happen</label>
				</div>
			</div>
			<div class="game-recap--status-message game-recap--shift-{{shift?.status}}">
				<h3 class="center-text">{{shiftStatus()}}</h3>
			</div>
			<p class="no-mb center-text">Did you experience any tech issues with your game, sound equipment, or this recap form? <a target="_blank" href="{{'/support?venuename=' + encodedVenue()}}">Report an issue.</a></p>
      <p class="error-message center-text">{{ err }}</p>
    </div>

		<div class="game-recap--sections">
			<div class="game-recap--internal-section">
				<div class="game-recap--section-bubble game-recap--stats-container">
					<h3 class="game-recap--section-header">Game Details</h3>
					<app-game-recap-stats [game]="game" [shift]="shift" [mode]="editMode === 'stats' ? 'edit' : ''"
						(cancelEdit)="editMode = ''" (doneEditing)="updateShift($event)"
						(endGame)="endGame()"></app-game-recap-stats>
					<i *ngIf="editMode === '' && shift.status !== 'approved'"
						class="material-icons game-recap--edit-section" (click)="editMode = 'stats'">edit</i>
				</div>
				<div class="game-recap--section-bubble game-recap--timecard-container">
					<h3 class="game-recap--section-header">Timecard</h3>
					<app-game-recap-shift [shift]="shift" [mode]="editMode === 'shift' ? 'edit' : ''"
						(endingShift)="endShift()" (cancelEdit)="editMode = ''"
						(doneEditing)="updateTimeWorked($event)"></app-game-recap-shift>
					<i *ngIf="editMode === '' && game.status !== 'live' && shift.status !== 'approved'"
						class="material-icons game-recap--edit-section" (click)="editMode = 'shift'">edit</i>
				</div>
			</div>
			<div class="game-recap--public-section">
				<div *ngIf="!shift.no_show && !game.private_event" class="game-recap--section-bubble">
					<h3 class="game-recap--section-header">Public Recap</h3>
					<app-game-recap-public [game]="game" [user]="user" [shift]="shift"
						[editing]="editMode === 'public' ? true : false" (cancelEdit)="editMode = ''"
						(doneEditing)="editMode = ''"></app-game-recap-public>
					<i *ngIf="editMode === '' && shift.status !== 'approved'"
						class="material-icons game-recap--edit-section" (click)="editMode = 'public'">edit</i>
				</div>
			</div>
		</div>
		<div *ngIf="editMode === '' && (shift.status === 'draft' || shift.status === 'review' || shift.status === 'submitted')"
			class="game-recap--publish flex flex-column items-center">
			<button class="recap-ctrl-red" (click)="startVerify('stats')">{{shift.status === 'draft' ? 'Submit' :
				'Update'}} Recap</button>
		</div>
	</div>
</div>

<div *ngIf="game.status === 'live' || (user?.admin && game.status !== 'finalized')" class="game-recap--to-game">
	<button (click)="backToGame()" class="recap-ctrl-red-outline">
		<i class="material-icons">arrow_back</i>
		<span>Back to Game</span>
	</button>
</div>

<ng-template #loadMsg>
	<h2 class="center-text lg-mt">{{loading}}</h2>
</ng-template>

<app-basic-modal *ngIf="publicMsgs.length" [title]="'Public Recap has Errors'" [template]="publicVerify" [cancel]="''"
	[action]="'Go Back'" (doAction)="cancelVerify()"></app-basic-modal>

<ng-template #publicVerify>
	<p>The following messages were generated when attempting to publish your recap:</p>
	<ul class="error-list std-mb">
		<li *ngFor="let m of publicMsgs">{{m}}</li>
	</ul>
</ng-template>

<app-basic-modal *ngIf="verifying === 'stats'" [title]="'Game Detail Verification'" [template]="statVerify"
	[action]="''" [cancel]="''" [hideX]="true" [staticBackground]="true"></app-basic-modal>

<ng-template #statVerify>
	<app-game-recap-stats *ngIf="!loading" [game]="game" [shift]="shift" [mode]="'verify'" (cancelEdit)="cancelVerify()"
		(doneEditing)="updateShift($event)"></app-game-recap-stats>
</ng-template>

<app-basic-modal *ngIf="verifying === 'shift'" [title]="'Shift Verification'" [template]="timeVerify" [action]="''"
	[cancel]="''" [hideX]="true" [staticBackground]="true"></app-basic-modal>

<ng-template #timeVerify>
	<app-game-recap-shift *ngIf="!loading" [shift]="shift" [mode]="'verify'" (cancelEdit)="cancelVerify()"
		(doneEditing)="updateTimeWorked($event)"></app-game-recap-shift>
</ng-template>
