import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.css']
})
export class SkeletonLoaderComponent implements OnInit {

  /**
  * Number of times this skeleton loader
  * will render
  */
  @Input() repeat: number

  repeatable: any[] = []

  constructor() { }

  ngOnInit(): void {
    this.repeatable = Array.from({ length: this.repeat })
  }

}
