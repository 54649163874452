<div *ngIf="shift?.user" class="hrz-between">
	<div class="vert-center clickable" (click)="startExpand()">
		<span>
			<span>{{shift.user.name}} {{shift.user.employee_id === user.employee_id.toString() ? '(You)' : ''}}</span>
			<span *ngIf="shift.work[0]">
				<br class="breakpoint-420">
				<span *ngIf="shift.status !== 'live'; else activeShift" class="sm-btn-grey md-ml">Shift Closed</span>
				<ng-template #activeShift>
					<span class="sm-btn-red std-ml">Shift Open</span>
				</ng-template>
			</span>
		</span>
	</div>
	<div *ngIf="!loading" class="hrz-start">
		<i *ngIf="!shift.warn_delete" (click)="shift.warn_delete = true" class="material-icons clickable sm-ml"
			style="color:brown;">delete_forever</i>
		<div class="vert-center" *ngIf="shift.warn_delete">
			<strong class="sm-ml">Delete this shift?</strong>
		</div>
		<div *ngIf="shift.warn_delete">
			<i (click)="deleteShift()" class="material-icons clickable sm-ml">done</i>
			<i (click)="shift.warn_delete = false" class="material-icons clickable sm-ml">clear</i>
		</div>
	</div>
	<div *ngIf="shift.warn_delete && loading">Deleting...</div>
</div>
<div *ngIf="expanded === index" class="sm-py hrz-between">
	<div *ngIf="editWorkTimes">
		<label>Started Work @ Venue</label>
		<div class="timebox">
			<input type="date" [(ngModel)]="editWorkTimes.start.date">
			<input type="time" [(ngModel)]="editWorkTimes.start.time">
		</div>
		<label *ngIf="shift.status !== 'live'">Finished Work @ Venue</label>
		<div *ngIf="shift.status !== 'live'" class="timebox">
			<input type="date" [(ngModel)]="editWorkTimes.end.date">
			<input type="time" [(ngModel)]="editWorkTimes.end.time">
		</div>
	</div>
	<div *ngIf="!editWorkTimes" class="vert-center">
		<span class="mb-10"><strong>Started:</strong> {{timeLocal(shift.work[0].start_work)}}</span>
		<span *ngIf="shift.status !=='live'" class="mb-10"><strong>Ended:</strong> {{shift.work[0].end_work ? timeLocal(shift.work[0].end_work) :
			'---'}}</span>
	</div>
	<div *ngIf="!loading" class="vert-center std-ml clickable">
		<span *ngIf="editWorkTimes">
			<i class="material-icons" (click)="saveWorkTimes()">done</i>
			<i class="material-icons md-ml" (click)="editWorkTimes = undefined">clear</i>
		</span>
		<div *ngIf="!editWorkTimes && !resetWarn" class="vert-start">
			<button class="action-button mb-10 flex justify-start items-center" (click)="startTimeEdit()">
				<i class="material-icons">create</i>
				<span>Edit Shift</span>
			</button>
			<button *ngIf="shift.status !== 'live'" class="action-button mb-10 flex justify-start items-center"
				(click)="resetWarn = true">
				<i class="material-icons">restart_alt</i>
				<span>Restart Shift</span>
			</button>

			<button *ngIf="shift.status === 'live'" class="action-button mb-10 flex justify-start items-center text-red"
				(click)="endShiftWarn = true">
				<i class="material-icons">timer_off</i>
				<span>End Shift</span>
			</button>

		</div>
		<div *ngIf="resetWarn" class="vert-start">
			<span>Reopen Shift?</span>
			<div>
				<i class="material-icons md-ml" (click)="reopenShift()">done</i>
				<i class="material-icons md-ml" (click)="resetWarn = false">clear</i>
			</div>
		</div>

		<div *ngIf="endShiftWarn" class="vert-start">
			<span>End this Shift?</span>
			<div>
				<i class="material-icons md-ml" (click)="endShift()">done</i>
				<i class="material-icons md-ml" (click)="endShiftWarn = false">clear</i>
			</div>
		</div>
	</div>
	<div *ngIf="!shift.warn_delete && loading">Loading...</div>
</div>