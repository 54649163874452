<h2 *ngIf="loading" class="loading-message">Loading Recaps...</h2>

<h2 *ngIf="err" class="error-message">{{err}}</h2>

<div *ngIf="!loading" class="routed-container">
	<div class="routed-content">
		<h2 class="center-text no-m std-p recap-title">Outstanding Recaps</h2>
		<app-recap-stub *ngFor="let r of recaps" [stub]="r"></app-recap-stub>
	</div>
</div>
