<div class="tbl-brd-t">
	<div class="team-line md-py md-text clickable">
		<i *ngIf="teamIdx !== detailIdx" (click)="toggleDetail()" class="material-icons vert-middle-text">unfold_more</i>
		<i *ngIf="teamIdx === detailIdx" (click)="toggleDetail()" class="material-icons vert-middle-text">unfold_less</i>
		<span class="md-ml t-name" (click)="toggleDetail()">{{teamNumDisplay()}} - {{team.team_name}}{{team.manual ? ' (manual)' : ''}}</span>
		<div *ngIf="game.status !== 'ended' && !finalScore; else finalScoreEditor" class="vert-center right-text" (click)="toggleDetail()">
			<span class="team-score">{{calcScores()}}</span>
		</div>
	</div>
	<div *ngIf="teamIdx === detailIdx">
		<div class="hrz-start">
			<div class="vert-center" (click)="switchTab('score')">
				<button [ngClass]="{'tab-btn-active': detailType === 'score', 'tab-btn-inactive': detailType !== 'score'}">Score Breakdown</button>
			</div>
			<div class="vert-center" (click)="switchTab('player')">
				<button [ngClass]="{'tab-btn-active': detailType === 'player', 'tab-btn-inactive': detailType !== 'player'}">Player List</button>
			</div>
		</div>
		<div class="folder-bottom">
			<div *ngIf="detailType === 'player'">
				<div *ngFor="let p of team.players; let i = index">
					<div *ngIf="capTarget === i && !p.is_captain" class="hrz-start">
						<div class="vert-center">
							<i *ngIf="!p.is_captain" class="material-icons">star_outline</i>
						</div>
						<div class="md-ml">
							<span class="icon-text-match">Make {{p.player_name}} team captain?</span>
						</div>
						<div class="vert-center">
							<div>
								<span class="yes-btn" (click)="setCaptain(p)">Yes</span>
								<span class="no-btn" (click)="capTarget = null">No</span>
							</div>
						</div>
					</div>
					<div *ngIf="capTarget !== i || p.is_captain" class="hrz-start">
						<div (click)="capTarget = i">
							<i *ngIf="p.is_captain" class="material-icons">star</i>
							<i *ngIf="!p.is_captain" class="material-icons">star_outline</i>
						</div>
						<div class="md-ml">
							<span class="icon-text-match">{{p.player_name}}</span>
						</div>
					</div>
				</div>
				<div *ngIf="!confirmKick;" class="error-message std-mt" (click)="confirmKick = true">
					<i class="material-icons remover">remove_circle</i>
					<span> Kick Team</span>
				</div>
			</div>
			<div *ngIf="detailType === 'score'">
				<app-round-score *ngFor="let r of game.question_set.rounds" [game]="game" [team]="team" [breakdown]="game | correctAnswers:r.round_id:team.team_code" [round]="r"></app-round-score>
			</div>
		</div>
	</div>
</div>

<ng-template #finalScoreEditor>
	<div class="vert-center">
		<div *ngIf="!editingFinal && !team.manual" class="hrz-end team-detail--score-edit">
			<i class="material-icons" (click)="editingFinal = true">create</i>
			<span>{{score}}</span>
		</div>
		<div *ngIf="editingFinal || team.manual" class="hrz-end right-text team-detail--score-edit">
			<i *ngIf="!team.manual" class="material-icons" (click)="updateFinalScore()">done</i>
			<i *ngIf="!team.manual" class="material-icons" (click)="cancelFinalScoreEdit()">clear</i>
			<input type="number" [(ngModel)]="score" (focusout)="updateFinalScore(true)">
		</div>
	</div>
</ng-template>

<app-basic-modal 
	*ngIf="confirmKick === true"
	[title]="'Kick team ' + team.team_name + '?'"
	[template]="loading ? loadMsg : liveTeamConfirm"
	[cancel]="''"
	(doCancel)="confirmKick = false"></app-basic-modal>

<ng-template #liveTeamConfirm>
	<p *ngIf="!team.manual"><strong>If this team has stopped playing or is submitting answers on pen and paper instead, choose "Keep Scores".</strong> The app will no longer expect submissions from this team, but you will still see and be able to make changes to their score.</p>
	<p>Deleting the team will remove them from the game entirely. <strong>If you delete a team, their scores will be permanently lost.</strong></p>
	<div class="hrz-between">
		<button *ngIf="!team.manual" (click)="downgradeTeam()" class="basic-btn-lgt-blue">Keep Scores</button>
		<button (click)="removeTeam()" class="basic-btn-red">Delete Team</button>
	</div>
</ng-template>

<ng-template #loadMsg>
	<h3>Kicking...</h3>
</ng-template>