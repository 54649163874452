import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SaveResult } from './game.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  rootUri = environment.root_uri;

  constructor(
    private http: HttpClient
  ) { }

  getToken(): string {
    return localStorage.getItem('auth_token');
  }

  setToken(token: string): void {
    if (!token) {
      localStorage.removeItem('auth_token');
    } else {
      localStorage.setItem('auth_token', token);
    }
  }

  getHostToken(username: string, password: string, honeypot: string = "") {
    const body = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('hp', honeypot);
    return this.http.post(this.rootUri + "/api/host/token", body);
  }

  requestRefreshToken() {
    return this.http.get(this.rootUri + "/api/host/refresh-token");
  }

  getHostDetail() {
    if(this.getToken()) {
      let user = localStorage.getItem("user_detail")
      if (user) {
        return of(JSON.parse(user))
      }
    }
    else localStorage.removeItem('user_detail')
    return this.http.get(this.rootUri + "/api/host/details");
  }

  updateUser(updater: any) {
  	return this.http.post(this.rootUri + "/api/host/update", updater);
  }

  getCurrentVersion() {
  	return this.http.get(`${this.rootUri}/api/version?r=${Math.random()}`)
  }

  requestResetPassword(email) {
    return this.http.post(this.rootUri + "/api/password-reset-request", {email, source: "host"});
  }

  saveNewPassword(token, new_password){
    return this.http.post(this.rootUri + `/api/password-reset/${token}`, {new_password});
  }

}
