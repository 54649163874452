import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../classes/User';

@Component({
	selector: 'app-main-menu',
	templateUrl: './main-menu.component.html',
	styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit {

	@Input() user: User;

	@Output() nav: EventEmitter<any[]> = new EventEmitter();
	@Output() logout: EventEmitter<boolean> = new EventEmitter();
	@Output() close: EventEmitter<boolean> = new EventEmitter();

	ver = "";

	constructor() { }

	ngOnInit(): void {
		this.ver = localStorage.getItem('api_version')
	}

	emitClose() {
		this.close.emit(true);
	}

	emitNav(route: any[]) {
		this.nav.emit(route);
	}

	emitLogout() {
		this.logout.emit(true);
	}

}
