export class User {
	name: string;
	token: string;
	employee_id: number;
	email: string;
	admin: boolean;
	venmo: string;
	userid: string;
  notifications: HostNotification[];

	constructor(us) {
		this.token = us.token;
		this.name = us.name;
		if (us.employee_id) {
			this.employee_id = (typeof us.employee_id === "number")
				? us.employee_id
				: parseInt(us.employee_id, 10);
		}
		this.email = us.email;
		this.admin = (us.admin) ? true : false;
		this.venmo = (us.venmo) ? us.venmo : "";
		this.userid = us.userid;
    this.notifications = us.notifications;
	}

	shortName() {
		let prts = this.name.split(' ');
		if (prts.length < 2) return prts[0];
		else return `${prts[0]} ${prts[prts.length - 1][0]}`;
	}
}
