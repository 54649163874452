<div class="md-p hrz-between stub-box">
	<div class="vert-center">
		<strong>{{stub.venue_name}}</strong>
		<em *ngIf="stub.event_type === 'trivia' || stub.event_type === 'slam'">{{stub.event_type === 'trivia' ? 'Pub Quiz' : 'Survey Slam'}}</em>
		<em *ngIf="stub.event_type !== 'trivia' && stub.event_type !== 'slam'">{{stub.event_type}}</em>
		<span>{{stub.event_date}} {{stub.event_time}}</span>
		<span>{{stub.recap_date ? 'AWAITING APPROVAL' : 'UNPUBLISHED'}}</span>
	</div>
	<div class="vert-center">
		<a *ngIf="!stub.is_live" href="{{stub.link}}" target="_blank">
			<button class="basic-btn-red">Edit Recap</button>
		</a>
		<div *ngIf="stub.is_live" class="error-message">Game is still live!</div>
	</div>
</div>
