<!-- <app-auth *ngIf="!authToken && !user && !loading" (login)="getUserInfo()"></app-auth> -->


<h2 class="center-text p-15" *ngIf="authToken && !user && !loading">
  <p>{{err}}</p>
  <button class="basic-btn-red" onclick="javascript:window.location.reload()">Retry</button>
</h2>

<app-header *ngIf="user && !loading; else unauthenticatedRoute" [user]="user" (logout)="removeUserInfo()"></app-header>

<h2 *ngIf="loading" class="loader">Loading...</h2>

<!-- Router Outlet for component that don't require app-header -->
<ng-template #unauthenticatedRoute>
  <router-outlet></router-outlet>
</ng-template>
