<div class="hrz-between sm-py round-slug">
	<div class="vert-center">
		<div class="hrz-start">
			<span *ngIf="!round.tiebreaker" class="icon-text-match" (click)="detail = !detail">Round {{round.round_order
				+ 1}}: </span>
			<span *ngIf="round.tiebreaker" class="icon-text-match" (click)="detail = !detail">Tiebreaker: </span>
			<i *ngIf="!team.manual" class="material-icons">{{(detail) ? 'arrow_drop_up' : 'arrow_drop_down'}}</i>
			<i *ngIf="game.round_index === round.round_id && game.teamSubmittedRound(round.round_id, team.team_code) && !team.manual"
				class="material-icons std-ml" (click)="resetAnswers()">restart_alt</i>
			<!-- <span *ngIf="!breakdown" class="hrz-center">(NOT SUBMITTED)</span>
			<span *ngIf="breakdown" class="hrz-center">(SUBMITTED)</span> -->
		</div>
	</div>
	<div *ngIf="!round.tiebreaker">
		<div class="vert-center">
			<div *ngIf="!team.manual && !editScore">
				<span *ngIf="!scoreIsSet()" class="icon-text-match">{{(game.teamDoubledRound(team.team_code,
					round.round_id)) ? "*" : ""}}{{totalScore()}}</span>
				<span *ngIf="scoreIsSet()" class="icon-text-match">
					<span *ngIf="manualScore()!==totalScore()">(edited)</span>
					{{manualScore()}}</span>
				<i class="material-icons sm-ml score-editor" (click)="startScoreEdit()">create</i>
			</div>
			<div *ngIf="team.manual">
				<input class="score-input" type="number" (focusout)="setScore()" [(ngModel)]="newScore">
			</div>
			<div *ngIf="editScore">
				<i class="material-icons sm-mr score-editor" (click)="setScore()">done</i>
				<i class="material-icons sm-mr score-editor" (click)="editScore = false">cancel</i>
				<input class="score-input" type="number" [(ngModel)]="newScore">
			</div>
		</div>
	</div>
</div>

<div *ngIf="detail" class="std-px">
	<div *ngIf="breakdown">
		<div *ngFor="let q of breakdown; let i = index">
			<div *ngIf="!round.single_answer || i === breakdown.length - 1" class="hrz-between sm-mb">
				<div class="hrz-start">
					<div class="sm-mr">
						<span>Q{{i + 1}}.</span>
					</div>
					<div>
						<span *ngFor="let a of q; let ai = index"><br *ngIf="ai">{{a.value}}</span>
					</div>
				</div>
				<div>
					<span *ngFor="let a of q; let ai = index"><br *ngIf="ai">{{a.score}}</span>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="!breakdown" class="hrz-center">
		<span>Not Yet Submitted...</span>
	</div>
</div>